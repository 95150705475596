import { useLocation } from 'react-router-dom';
import eq from 'lodash/eq';
import isEmpty from 'lodash/isEmpty';
import { DASHBOARD_VIEWS } from '../constants';

export const useViews = () => {
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const viewParam = params.get('view') || DASHBOARD_VIEWS.PLAN_VIEW;

  return {
    isPlanView: eq(viewParam, DASHBOARD_VIEWS.PLAN_VIEW),
    isMapView: eq(viewParam, DASHBOARD_VIEWS.MAP_VIEW),
    isListView: eq(viewParam, DASHBOARD_VIEWS.LIST_VIEW),
    isFullMap: eq(params.get('full-map'), 'true'),
    notViewParams: isEmpty(params.get('view')),
    notFullSizeMapParam: isEmpty(params.get('full-map')),
    params: params,
    pathname: pathname
  };
};
