/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { EXPAND_MAP, SHRINK_MAP } from '../../../assets/base_64_images';
import { useViews } from '../../hooks/views';
import Tooltip from '../Tooltips/Tooltip';

const MapSizeControl = () => {
  const history = useHistory();
  const { params, isFullMap, notFullSizeMapParam } = useViews();

  const handleResizeMap = () => {
    if (!notFullSizeMapParam) {
      params.delete('full-map');
    }
    params.append('full-map', !isFullMap);
    history.push({
      search: params.toString()
    });
  };

  return (
    <button
      onClick={handleResizeMap}
      className="cx_button cx_button--alpha py-[4px] px-3"
    >
      <Tooltip
        placement="bottom"
        tooltip={isFullMap ? 'Shrink map' : 'Expand map'}
      >
        <img
          src={`data:image/svg+xml;base64, ${
            isFullMap ? SHRINK_MAP : EXPAND_MAP
          }`}
          alt="expand map"
        />
      </Tooltip>
    </button>
  );
};

export default MapSizeControl;
