/* eslint-disable import/no-unresolved */
import { useDispatch, useSelector } from 'react-redux';
import { uiSlice } from '../store/ui';
import { has, chain, eq } from 'lodash';

const useUIStore = () => {
  const dispatch = useDispatch();

  const getModal = useSelector(state => state.ui.modal);

  const setModal = modal => {
    dispatch(uiSlice.actions.setModal(modal));
  };

  const getWaypointDragEvent = key =>
    useSelector(state => state.ui.waypointDragEvents[key]);

  const setWaypointDragEvent = key => value => {
    dispatch(uiSlice.actions.setWaypointDragEvent({ key, value }));
  };

  const clearModal = () => {
    dispatch(uiSlice.actions.clearModal());
  };

  const getDropdown = useSelector(state => state.ui.dropdown);

  const setDropdown = dropdown => {
    dispatch(uiSlice.actions.setDropdown(dropdown));
  };

  const clearDropdown = () => {
    dispatch(uiSlice.actions.clearDropdown());
  };

  const getFlyout = useSelector(state => state.ui.flyout);

  const setFlyout = flyout => {
    dispatch(uiSlice.actions.setFlyout(flyout));
  };

  const clearFlyout = () => {
    dispatch(uiSlice.actions.clearFlyout());
  };

  const getPreview = useSelector(state => state.ui.preview);

  const setPreview = preview => {
    dispatch(uiSlice.actions.setPreview(preview));
  };

  const clearPreview = () => {
    dispatch(uiSlice.actions.clearPreview());
  };

  const setPersisted = persisted => {
    dispatch(uiSlice.actions.setPersisted(persisted));
  };

  const isNotPersisted = useSelector(state => !state.ui.persisted);

  const addUnsavedForm = formId => {
    dispatch(uiSlice.actions.addUnsavedForm(formId));
  };

  const removeUnsavedForm = formId => {
    dispatch(uiSlice.actions.removeUnsavedForm(formId));
  };

  const unsavedForms = useSelector(state => state.ui.unsavedForms);

  const areOtherFormsSaved = formId =>
    chain(unsavedForms)
      .filter(id => !eq(id, formId))
      .isEmpty()
      .value();

  const getToasts = useSelector(state => state.ui.toasts);

  const addToast = toast => {
    if (!has(toast, 'id', 'type', 'message')) {
      throw new TypeError('Toast must have id, type, message');
    }

    dispatch(uiSlice.actions.addToast(toast));
  };

  const removeToast = toast => {
    if (!has(toast, 'id')) {
      throw new TypeError('Toast must have id');
    }

    dispatch(uiSlice.actions.removeToast(toast));
  };

  const clearToasts = () => {
    dispatch(uiSlice.actions.clearToasts());
  };

  const getPersistedRoutes = useSelector(state => state.ui.persistedRoutes);

  const isRoutePersisted = key => getPersistedRoutes[key];

  const setPersistedRoute = key => persisted => {
    dispatch(uiSlice.actions.setPersistedRoute({ key, persisted }));
  };

  const getLoading = useSelector(state => state.ui.loading);

  const setLoading = loading => {
    dispatch(uiSlice.actions.setLoading(loading));
  };

  const getRequestsInProgress = useSelector(
    state => state.ui.requestsInProgress
  );

  const setRequestsInProgress = (request, value) => {
    dispatch(uiSlice.actions.setRequestsInProgress({ request, value }));
  };

  const getHovered = useSelector(state => state.ui.hovered);

  const setHovered = type => id => {
    dispatch(uiSlice.actions.setHovered({ type, id }));
  };

  const getAuthenticityToken = useSelector(state => state.ui.authenticityToken);

  const setAuthenticityToken = authenticityToken => {
    dispatch(uiSlice.actions.setAuthenticityToken(authenticityToken));
  };

  const getPickupRepositioning = useSelector(
    state => state.ui.pickupRepositioning
  );

  const setPickupRepositioning = repositioning => {
    dispatch(uiSlice.actions.setPickupRepositioning(repositioning));
  };

  const getDropOffRepositioning = useSelector(
    state => state.ui.dropOffRepositioning
  );

  const setDropOffRepositioning = repositioning => {
    dispatch(uiSlice.actions.setDropOffRepositioning(repositioning));
  };

  const getOneLocationOnlyRepositioning = useSelector(
    state => state.ui.oneLocationOnlyRepositioning
  );

  const setOneLocationOnlyRepositioning = repositioning => {
    dispatch(uiSlice.actions.setOneLocationOnlyRepositioning(repositioning));
  };

  const setMapCenter = mapCenter => {
    dispatch(uiSlice.actions.setMapCenter(mapCenter));
  };

  const getMapCenter = useSelector(state => state.ui.mapCenter);

  const isSubscriptionUnpaid = useSelector(
    state => state.ui.isSubscriptionUnpaid
  );

  return {
    getModal,
    setModal,
    clearModal,
    getDropdown,
    setDropdown,
    clearDropdown,
    getFlyout,
    setFlyout,
    clearFlyout,
    getPreview,
    setPreview,
    clearPreview,
    setPersisted,
    isNotPersisted,
    addUnsavedForm,
    removeUnsavedForm,
    unsavedForms,
    areOtherFormsSaved,
    getToasts,
    addToast,
    removeToast,
    clearToasts,
    getPersistedRoutes,
    isRoutePersisted,
    setPersistedRoute,
    getLoading,
    setLoading,
    getHovered,
    setHovered,
    getRequestsInProgress,
    setRequestsInProgress,
    getAuthenticityToken,
    setAuthenticityToken,
    getPickupRepositioning,
    setPickupRepositioning,
    getDropOffRepositioning,
    setDropOffRepositioning,
    getOneLocationOnlyRepositioning,
    setOneLocationOnlyRepositioning,
    getMapCenter,
    setMapCenter,
    isSubscriptionUnpaid,
    getWaypointDragEvent,
    setWaypointDragEvent
  };
};

export default useUIStore;
