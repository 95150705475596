import { createSlice } from '@reduxjs/toolkit';
import eq from 'lodash/eq';
import map from 'lodash/map';
import find from 'lodash/find';

const initialState = null;

export const manifestsSlice = createSlice({
  name: 'manifests',
  initialState,
  reducers: {
    setManifests: (state, action) => action.payload,
    addManifest: (state, action) => [...state, action.payload],
    updateManifest: (state, action) =>
      map(state, manifest =>
        eq(manifest.id, action.payload.id) ? action.payload : manifest
      ),
    updateManifests: (state, action) =>
      map(
        state,
        manifest => find(action.payload, { id: manifest.id }) || manifest
      )
  }
});

export default manifestsSlice.reducer;
