export const importDeliveriesAttributes = [
  'job_name',
  'delivery_size',
  'pickup_address',
  'pickup_duration_in_minutes',
  'pickup_contact',
  'pickup_notes',
  'pickup_email',
  'pickup_phone_number',
  'pickup_notifications',
  'drop_off_address',
  'drop_off_duration_in_minutes',
  'drop_off_contact',
  'drop_off_notes',
  'drop_off_email',
  'drop_off_phone_number',
  'drop_off_notifications',
  'pickup_date',
  'pickup_window_start_at',
  'pickup_window_end_at',
  'drop_off_window_start_at',
  'drop_off_window_end_at',
  'email_contacts',
  'sms_contacts'
];

export const importOrdersHeaders = [
  'Order Name',
  'Vehicle Size (Required)',
  'Number Of Items (Required)',
  'Pickup Address (Street, City, State, Postal) (Required)',
  'Pickup Business Name (Required)',
  'Pickup Contact (Required)',
  'Pickup Contact Phone Number',
  'Pickup Notes',
  'Drop-Off Address (Street, City, State, Postal) (Required)',
  'Drop-Off Business Name (Required)',
  'Drop-Off Contact (Required)',
  'Drop-Off Contact Phone Number (Required)',
  'Drop-Off Notes',
  'Pickup Date (Example: 31/12/2021) (Required)',
  'Pickup At (Example: 8:00 AM) (Required)',
  'Drop-Off At (Example: 5:00 PM) (Required)',
  'Recipient Notification Emails',
  'Recipient Notification SMS Numbers',
  'Order Type',
  'Estimated Weight'
];

export const importDeliveriesHeaders = [
  'Reference ID (Required)',
  'Delivery Size (Required)',
  'Pickup Address (Street, City, State, Postal) (Required)',
  'Pickup Duration (Minutes)',
  'Pickup Contact',
  'Pickup Notes',
  'Pickup Contact Email',
  'Pickup Contact Phone Number',
  'Pickup Notifications (Email, SMS, or Both)',
  'Drop-Off Address (Street, City, State, Postal) (Required)',
  'Drop-Off Duration (Minutes)',
  'Drop-Off Contact',
  'Drop-Off Notes',
  'Drop-Off Contact Email',
  'Drop-Off Contact Phone Number',
  'Drop-Off Notifications (Email, SMS, or Both)',
  'Pickup Date (Example: 31/12/2021) (Required)',
  'Pickup Window Start At (Example: 8:00 AM) (Required)',
  'Pickup Window End At (Example: 5:00 PM) (Required)',
  'Drop-Off Window Start At (Example: 8:00 AM) (Required)',
  'Drop-Off Window End At (Example: 5:00 PM) (Required)',
  'Recipient Notification Emails',
  'Recipient Notification SMS Numbers'
];

export const importDeliveriesHeadersWithoutRulesAndHints = [
  'Reference ID',
  'Delivery Size',
  'Pickup Address',
  'Pickup Duration',
  'Pickup Contact',
  'Pickup Notes',
  'Pickup Contact Email',
  'Pickup Contact Phone Number',
  'Pickup Notifications',
  'Drop-Off Address',
  'Drop-Off Duration',
  'Drop-Off Contact',
  'Drop-Off Notes',
  'Drop-Off Contact Email',
  'Drop-Off Contact Phone Number',
  'Drop-Off Notifications',
  'Pickup Date',
  'Pickup Window Start At',
  'Pickup Window End At',
  'Drop-Off Window Start At',
  'Drop-Off Window End At',
  'Recipient Notification Emails',
  'Recipient Notification SMS Numbers'
];

export const importDeliveriesCSVTemplate = `data:text/csv;charset=utf-8,${importDeliveriesHeaders.map(
  string => (string === null ? '' : `"${string}"`)
)}`;

export const importOrdersCSVTemplate = `data:text/csv;charset=utf-8,${importOrdersHeaders.map(
  string => (string === null ? '' : `"${string}"`)
)}`;

export const generateCSVstring = csvMatrix =>
  `${csvMatrix
    .map(row =>
      row.map(string => (string === null ? '' : `"${string}"`)).join(',')
    )
    .join('\n')}`;
