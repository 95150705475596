import { createSlice } from '@reduxjs/toolkit';
import { eq, filter } from 'lodash';

const initialState = null;

export const stopsSlice = createSlice({
  name: 'stops',
  initialState,
  reducers: {
    setStops: (state, action) => action.payload,
    setStopsBy: (state, action) => [
      ...filter(
        state,
        stop => !eq(stop[action.payload.key], action.payload.value)
      ),
      ...action.payload.stops
    ],
    addStops: (state, action) => [...state, ...action.payload]
  }
});

export default stopsSlice.reducer;
