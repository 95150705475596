import { createSlice } from '@reduxjs/toolkit';
import { eq, isNil, map, find } from 'lodash';

const initialState = null;

export const deliveriesSlice = createSlice({
  name: 'deliveries',
  initialState,
  reducers: {
    setDeliveries: (state, action) => action.payload,
    addDelivery: (state, action) => [
      ...(!isNil(state) ? state : []),
      action.payload
    ],
    addDeliveries: (state, action) => [
      ...(!isNil(state) ? state : []),
      ...action.payload
    ],
    updateDelivery: (state, action) =>
      map(state, delivery =>
        eq(delivery.id, action.payload.id) ? action.payload : delivery
      ),
    updateDeliveries: (state, action) =>
      map(
        state,
        delivery => find(action.payload, { id: delivery.id }) || delivery
      ),
    updateDeliveriesBy: (state, action) =>
      map(state, delivery =>
        eq(delivery[action.payload.key], action.payload.value)
          ? { ...delivery, ...action.payload.delivery }
          : delivery
      )
  }
});

export default deliveriesSlice.reducer;
