import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import MapPin from './MapPin';
import googleMapSilver from '../../google-map-silver.json';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import eq from 'lodash/eq';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import reject from 'lodash/reject';
import differenceWith from 'lodash/differenceWith';
import useGoogleMapDirections from '../../hooks/googleMapDirections';
import FilterVehicle from './FilterVehicle';
import RouteStyle from './RouteStyle';
import MapSizeControl from './MapSizeControl';

const FIT_BOUNDS_PADDING = { top: 75, left: 30, right: 30, bottom: 20 }; // numbers are supposedly pixels according to the google maps docs
const DEFAULT_CENTER = { lat: 44.955871, lng: -93.177222 };
const DEFAULT_ZOOM = 11;

const GoogleMap = props => {
  const {
    allowMapGestures,
    altMapSizeControl,
    center,
    googleApiKey,
    maxZoom,
    showMapTypeControl,
    showTraffic,
    showMapControl,
    showRouteStyleControl,
    zoom,
    directions,
    isConnectRedesign,
    isForDriverLocationOnly,
    children,
    driverOrders
  } = props;
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsInstance, setMapsInstance] = useState(null);
  const componentRouteStyle = driverOrders ? 'driverRoute' : 'straightLine';
  const {
    pins,
    previousPins,
    checkedVehiclesIds,
    setCheckedVehiclesIds,
    sendToDispatchChecked,
    setSendToDispatchChecked,
    handleRouteStyleChange
  } = useGoogleMapDirections({
    mapInstance,
    mapsInstance,
    directions,
    componentRouteStyle
  });

  const samePinsLocations = (prevPins, currentPins) => {
    const pinKeys = ['key', 'lat', 'lng'];

    if (isForDriverLocationOnly) return;
    return isEqual(
      sortBy(
        map(reject(prevPins, { type: 'vehicle' }), pin => pick(pin, pinKeys)),
        'key'
      ),
      sortBy(
        map(reject(currentPins, { type: 'vehicle' }), pin =>
          pick(pin, pinKeys)
        ),
        'key'
      )
    );
  };

  useEffect(() => {
    if (
      !isNil(mapInstance) &&
      !isNil(mapsInstance) &&
      !samePinsLocations(previousPins, pins)
    ) {
      handleLocationChange(mapInstance, mapsInstance, pins);

      if (showMapControl) {
        setMapControl(mapInstance, mapsInstance, pins);
      }
    }
  }, [mapInstance, mapsInstance, pins]);

  const getMapBounds = (map, maps, pins) => {
    const bounds = new maps.LatLngBounds();

    pins.forEach(pin => {
      bounds.extend(new maps.LatLng(pin.lat, pin.lng));
    });

    return bounds;
  };

  const handleLocationChange = (map, maps, pins) => {
    if (showTraffic) {
      const trafficLayer = new maps.TrafficLayer();
      trafficLayer.setMap(map);
    }

    if (showMapTypeControl) {
      map.mapTypeControl = true;
    }

    if (isEmpty(pins)) return;

    const bounds = getMapBounds(map, maps, pins);
    map.fitBounds(bounds, FIT_BOUNDS_PADDING);

    // Resets the zoom level to 16 if it's higher by default after fitBounds() occurs.
    // For example, if there is only one point
    const listener = maps.event.addListener(map, 'idle', () => {
      if (map.getZoom() > maxZoom) map.setZoom(maxZoom);
      google.maps.event.removeListener(listener);
    });
  };

  const handleCreateMapOptions = () => {
    return {
      styles: googleMapSilver,
      disableDefaultUI: true,
      gestureHandling: allowMapGestures ? 'greedy' : 'auto',
      fullscreenControl: driverOrders
    };
  };

  const handleGoogleApiLoaded = ({ map, maps }) => {
    setMapInstance(map);
    setMapsInstance(maps);
  };

  const setMapControl = (map, maps, pins) => {
    const controlDiv = document.createElement('div');
    ReactDOM.render(renderMapControl({ map, maps, pins }), controlDiv);
    if (!eq(map.controls[maps.ControlPosition.TOP_RIGHT].length, 0))
      map.controls[maps.ControlPosition.TOP_RIGHT].pop();

    map.controls[maps.ControlPosition.TOP_RIGHT].push(controlDiv);
  };

  const handleZoomIn = map => {
    map.setZoom(map.getZoom() + 1);
  };

  const handleZoomOut = map => {
    map.setZoom(map.getZoom() - 1);
  };

  /* eslint-disable react/prop-types */
  const renderMapControl = ({ map, maps, pins }) => {
    return (
      <div className="flex flex-col items-end mr-6 mt-6">
        {isConnectRedesign ? (
          <button
            className="cx_button cx_button--beta cx_button--i cx_i cx_i--blue-eye cx_l--margin-bottom-24 cx_l--margin-right-0"
            onClick={() => handleLocationChange(map, maps, pins)}
          >
            View All
          </button>
        ) : (
          <button
            className="cx_button cx_button--gray cx_button--i cx_i cx_i--gray-eye cx_l--margin-bottom-24 cx_l--margin-right-0"
            onClick={() => handleLocationChange(map, maps, pins)}
          >
            View All Activity
          </button>
        )}
        <button
          className="cx_button cx_button--alpha cx_i cx_i--add-white cx_l--margin-bottom-8 cx_b--background-size-16 cx_l--width-36px cx_l--height-36 cx_l--margin-right-0"
          onClick={() => handleZoomIn(map)}
        />
        <button
          className="cx_button cx_button--alpha cx_i cx_i--dash-white cx_b--background-size-16 cx_l--width-36px cx_l--height-36"
          onClick={() => handleZoomOut(map)}
        />
      </div>
    );
  };
  /* eslint-enable react/prop-types */
  const directionsWithVehicles = directions.filter(
    direction => direction?.vehicle_id
  );
  const directionsWithUniqVehiclesIds = uniqBy(
    directionsWithVehicles,
    'vehicle_id'
  );
  const directionsVehicleIds = map(directionsWithUniqVehiclesIds, 'vehicle_id');

  useEffect(() => {
    setCheckedVehiclesIds(
      directionsWithUniqVehiclesIds.map(vehicle => vehicle?.vehicle_id)
    );
  }, [directions.length, JSON.stringify(directionsVehicleIds)]);

  const filteredVehiclesPins = pins.filter(pin =>
    checkedVehiclesIds.includes(pin?.vehicle_id)
  );
  const anyVehiclePin = pins.filter(pin => pin?.vehicle_id);
  const anySendToDispatchPins = pins.filter(pin => pin?.sent_to_dispatch);
  const sendToDispatchPins = sendToDispatchChecked ? anySendToDispatchPins : [];
  const filteredPins = [...filteredVehiclesPins, ...sendToDispatchPins];
  const alwaysShownPins = differenceWith(
    pins,
    [...anyVehiclePin, ...anySendToDispatchPins],
    (pin1, pin2) => pin1?.key === pin2?.key
  );
  const pinsToShow = [...filteredPins, ...alwaysShownPins];

  return (
    <div className="cx_l--position-relative cx_l--height-inherit">
      {showMapControl && (
        <div className="absolute top-6 left-6 z-20 flex items-start space-x-4">
          {isConnectRedesign && !altMapSizeControl && <MapSizeControl />}
          {altMapSizeControl}
          <FilterVehicle
            setCheckedVehiclesIds={setCheckedVehiclesIds}
            checkedVehiclesIds={checkedVehiclesIds}
            setSendToDispatchChecked={setSendToDispatchChecked}
            sendToDispatchChecked={sendToDispatchChecked}
            directionsWithVehicles={directionsWithVehicles}
            anySendToDispatchPins={anySendToDispatchPins}
            directionsWithUniqVehiclesIds={directionsWithUniqVehiclesIds}
          />
          {showRouteStyleControl && isConnectRedesign && (
            <RouteStyle onChange={handleRouteStyleChange} />
          )}
        </div>
      )}
      <GoogleMapReact
        center={center}
        zoom={zoom}
        defaultCenter={DEFAULT_CENTER}
        defaultZoom={DEFAULT_ZOOM}
        bootstrapURLKeys={{
          key: googleApiKey
        }}
        yesIWantToUseGoogleMapApiInternals
        options={handleCreateMapOptions}
        onGoogleApiLoaded={handleGoogleApiLoaded}
      >
        {map(pinsToShow, pin => (
          <MapPin
            key={pin.key || `lat${pin.lat}lng${pin.lng}`}
            type={pin.type}
            color={pin.color}
            lat={pin.lat}
            lng={pin.lng}
            tooltip={pin.tooltip}
            highlight={pin.highlight}
            onHoverStateChange={pin.onHoverStateChange}
            driverOrders={driverOrders}
          />
        ))}
        {children}
      </GoogleMapReact>
    </div>
  );
};

GoogleMap.defaultProps = {
  allowMapGestures: false,
  maxZoom: 16,
  showMapTypeControl: false,
  showMapControl: true,
  showRouteStyleControl: false,
  directions: [],
  driverOrders: false
};

GoogleMap.propTypes = {
  googleApiKey: PropTypes.string,
  allowMapGestures: PropTypes.bool,
  altMapSizeControl: PropTypes.node,
  center: PropTypes.shape({
    lat: PropTypes.any,
    lng: PropTypes.any
  }),
  driverOrders: PropTypes.bool,
  maxZoom: PropTypes.number,
  showMapTypeControl: PropTypes.bool,
  showTraffic: PropTypes.bool,
  showMapControl: PropTypes.bool,
  showRouteStyleControl: PropTypes.bool,
  zoom: PropTypes.number,
  directions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      color: PropTypes.string,
      suppressPolylines: PropTypes.bool,
      pins: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.any,
          lat: PropTypes.any,
          lng: PropTypes.any,
          type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          color: PropTypes.string,
          highlight: PropTypes.bool,
          onHoverStateChange: PropTypes.func
        })
      )
    })
  ),
  isConnectRedesign: PropTypes.bool,
  isForDriverLocationOnly: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.object)
};

// Prevent unnecessary API calls by checking if no props have changed.
// If props are all the same this component shouldn't update.
// JSON.stringify doesn't include functions so in the future if a prop is
// added which is a function, you may also need to include it in this contitional
export default React.memo(GoogleMap, (prevProps, nextProps) => {
  // The next two statements remove any React Nodes from the list of props
  // before comparing the JSON strings. If adding  a JSX/component prop, make sure to
  // remove them from `prev` and `next` or you will get an error like this:
  // Uncaught TypeError: Converting circular structure to JSON

  // eslint-disable-next-line no-unused-vars
  const { children: _c1, altMapSizeControl: _a1, ...prev } = prevProps;
  // eslint-disable-next-line no-unused-vars
  const { children: _c2, altMapSizeControl: _a2, ...next } = nextProps;

  if (JSON.stringify(prev) === JSON.stringify(next)) {
    return true;
  } else {
    return false;
  }
});
