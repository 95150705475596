import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PlaceholderImage from '../PlaceholderImage/PlaceholderImage';

const ImageUpload = props => {
  const {
    accept,
    maxFileSize,
    className,
    required,
    showRequiredIndicator,
    hasErrors,
    customErrorMessage,
    disabled,
    helpText,
    id,
    label,
    labelRef,
    onChange,
    onDragEnter,
    onDragLeave,
    name,
    value,
    placeholder,
    placeholderImage,
    placeholderWidth,
    placeholderHeight
  } = props;

  const [fileName, setFileName] = useState('');

  const imgPreviewContainer = useRef();
  const imgPreview = useRef();
  const imgUpload = useRef();
  const imgInput = useRef();
  const filePreview = useRef();

  const validRefs = () => {
    return (
      !!imgPreviewContainer.current &&
      !!imgPreview.current &&
      !!imgUpload.current &&
      !!imgInput.current &&
      !!filePreview.current
    );
  };

  useEffect(() => {
    if (value && typeof value === 'string') return setImageFromUrl(value);

    if (!validFile(value)) return;

    const reader = new FileReader();
    reader.onload = e => {
      if (supportedImage(value)) {
        setImageFromUrl(e.target.result);
      } else {
        setFilePreview(value);
      }
    };

    reader.readAsDataURL(value);
  }, [value]);

  const supportedImage = file => {
    const fileType = file?.type;
    return fileType === 'image/png' || fileType === 'image/jpeg';
  };

  const validFile = file => {
    const fileType = file?.type;
    if (accept) return accept.split(',').includes(fileType);

    return supportedImage(file) || fileType === 'application/pdf';
  };

  const processFileErrors = file => {
    if (!file) return ['no_file_selected'];

    const errors = [];
    if (!validFile(file)) {
      errors.push('invalid_type');
    }

    if (maxFileSize && file.size > maxFileSize) {
      errors.push('invalid_max_size');
    }

    return errors;
  };

  const setImageFromUrl = url => {
    if (!validRefs()) return;

    imgPreview.current.classList.remove('hidden');
    imgPreview.current.setAttribute('src', url);
    imgPreviewContainer.current.classList.remove('hidden');
    imgUpload.current.classList.add('hidden');
    filePreview.current.classList.add('hidden');
  };

  const setFilePreview = file => {
    if (!validRefs()) return;
    setFileName(file.name);
    filePreview.current.classList.remove('hidden');
    imgPreviewContainer.current.classList.remove('hidden');
    imgUpload.current.classList.add('hidden');
    imgPreview.current.classList.add('hidden');
  };

  const handleDragOver = e => e.preventDefault();

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    if (onDragEnter) {
      onDragEnter(e);
    }
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    if (onDragLeave) {
      onDragLeave(e);
    }
  };

  const handleDrop = e => {
    if (!validRefs()) return;

    imgInput.current.files = e.dataTransfer.files;
    handleChange();
    e.preventDefault();
  };

  const handleChange = () => {
    if (!validRefs()) return;

    const input = imgInput.current;
    const errors = processFileErrors(input.files?.[0]);
    if (errors.length > 0) {
      return handleRemove(null, errors);
    }

    onChange(input.files[0]);
  };

  const handleRemove = (evt, errors) => {
    if (!validRefs()) return;

    if (evt) evt.preventDefault();

    imgInput.current.value = '';
    imgPreview.current.setAttribute(
      'src',
      'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
    );
    imgPreviewContainer.current.classList.add('hidden');
    imgUpload.current.classList.remove('hidden');

    onChange(null, errors);
  };

  return (
    <div className={`${hasErrors ? 'has-error' : ''}`}>
      <label
        htmlFor={id}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={className}
        ref={labelRef}
      >
        {required && showRequiredIndicator && (
          <div className="mb-2">
            <abbr title="This field is required.">*</abbr> <span>{label}</span>
          </div>
        )}
        {(!required || !showRequiredIndicator) && (
          <div className="mb-2">{label}</div>
        )}
        <div ref={imgUpload}>
          {placeholderImage ? (
            <PlaceholderImage
              image={placeholderImage}
              className="inline-block"
              style={{
                width: placeholderWidth,
                height: placeholderHeight,
                cursor: disabled ? 'not-allowed' : 'pointer'
              }}
            />
          ) : (
            <div>{placeholder}</div>
          )}
        </div>
        <div
          className="cx_field--image-preview-container hidden"
          ref={imgPreviewContainer}
        >
          <button className="cx_field--image-remove" onClick={handleRemove} />
          <img
            className="img-responsive cx_field--image-preview"
            alt="Uploaded File"
            src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            ref={imgPreview}
          />
          <div className="text-lg text-navy p-5" ref={filePreview}>
            {fileName}
          </div>
        </div>
      </label>
      <div>{helpText}</div>
      {hasErrors && customErrorMessage && (
        <div className="help-block error-message"> {customErrorMessage} </div>
      )}
      <div className="hidden">
        <input
          id={id}
          name={name}
          onChange={handleChange}
          accept={accept}
          type="file"
          ref={imgInput}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

ImageUpload.defaultProps = {
  acccept: 'image/png,image/jpeg,application/pdf',
  value: null,
  placeholderImage: 'uploadPhoto',
  placeholderWidth: 225,
  placeholderHeight: 177
};

ImageUpload.propTypes = {
  accept: PropTypes.string,
  maxFileSize: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  showRequiredIndicator: PropTypes.bool,
  hasErrors: PropTypes.bool,
  customErrorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  labelRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func,
  placeholder: PropTypes.node,
  placeholderImage: PropTypes.oneOf([
    'uploadLogo',
    'uploadPhoto',
    'uploadFile',
    false
  ]),
  placeholderWidth: PropTypes.number,
  placeholderHeight: PropTypes.number,
  helpText: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]) // URL or File
};

export default ImageUpload;
