import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FloatingMenu from '../FloatingMenu';
import RadioButtons from '../../../components/RadioButtons/RadioButtons';
import useAnalytics from '../../hooks/useAnalytics';

const RouteStyle = ({ initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue);
  const { trackEvent } = useAnalytics();

  const handleChange = event => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
      trackEvent('Route Style - ' + event.target.value + ' Clicked');
    }
  };

  return (
    <FloatingMenu
      iconClassName="hidden"
      title="Route Style"
      containerClassName="relative"
    >
      <div className="p-4 pb-2">
        <RadioButtons
          name="route-style"
          fieldSetClassName=""
          value={value}
          options={[
            {
              name: 'Straight line',
              id: 'straight-line',
              value: 'straightLine',
              testId: 'straight-line'
            },
            {
              name: 'Driver route',
              id: 'driver-route',
              value: 'driverRoute',
              testId: 'driver-route'
            }
          ]}
          onChange={handleChange}
        />
      </div>
    </FloatingMenu>
  );
};

RouteStyle.defaultProps = {
  initialValue: 'straightLine',
  onChange: null
};

RouteStyle.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func
};

export default RouteStyle;
