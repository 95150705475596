import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useUIStore from './../hooks/uiStore';
import { map, isNil } from 'lodash';

const Toast = toast => {
  const { removeToast } = useUIStore();

  const close = () => {
    removeToast(toast);
  };

  useEffect(() => {
    setTimeout(() => {
      close();
    }, 20000);
  }, []);

  return (
    <div className="cx_toast">
      {!isNil(toast.type) && (
        <div className="cx_toast__aside">
          <div className="cx_toast__graphic">
            <i
              className={`cx_i cx_i--m cx_i--${(() => ({
                success: 'green-circle-check',
                warning: 'yellow-alert',
                error: 'x-circle-red'
              }))()[toast.type] || ''}`}
            />
          </div>
        </div>
      )}
      <div className="cx_toast__main">
        {!isNil(toast.message?.title) && (
          <h6 className="cx_toast__title">{toast.message?.title}</h6>
        )}
        {!isNil(toast.message?.content) && (
          <p className="cx_toast__content">{toast?.message?.content}</p>
        )}
        {!isNil(toast.link) && (
          <Link to={toast.link.url} className="cx_toast__link">
            {toast.link.text}
          </Link>
        )}
      </div>
      <button
        onClick={close}
        className="cx_toast__close cx_i cx_i--m cx_i--blue-close-x"
      />
    </div>
  );
};

const Toasts = () => {
  const { getToasts: toasts } = useUIStore();

  return (
    <div className="cx_toasts">
      {map(toasts, toast => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>
  );
};

export default Toasts;
