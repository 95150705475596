import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlags: (state, action) => action.payload
  }
});

export default featureFlagsSlice.reducer;
