/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import FloatingMenu from '../FloatingMenu';
import useMediaQuery from '../../hooks/useMediaQuery';

const FilterVehicle = ({
  checkedVehiclesIds,
  setCheckedVehiclesIds,
  setSendToDispatchChecked,
  sendToDispatchChecked,
  anySendToDispatchPins,
  directionsWithUniqVehiclesIds
}) => {
  const isTabletOrMobile = useMediaQuery('(max-width: 991px)');
  const isSmallDesktop = useMediaQuery(
    '(min-width: 1200px) and (max-width: 1250px)'
  );

  const handleOnChangeVehicleCheckbox = vehicle_id => {
    const alreadyChecked = checkedVehiclesIds.includes(vehicle_id);
    if (alreadyChecked) {
      setCheckedVehiclesIds(prevCheckedVehicles =>
        prevCheckedVehicles.filter(vId => vId !== vehicle_id)
      );
    } else {
      setCheckedVehiclesIds(prevCheckedVehicles => [
        ...prevCheckedVehicles,
        vehicle_id
      ]);
    }
  };

  const handleOnChangeSendToDispatch = () =>
    setSendToDispatchChecked(
      prevSendToDispatchChecked => !prevSendToDispatchChecked
    );

  const handleSelectAll = () => {
    setSendToDispatchChecked(true);
    setCheckedVehiclesIds(
      directionsWithUniqVehiclesIds.map(
        uniqVehicleId => uniqVehicleId.vehicle_id
      )
    );
  };

  return (
    <FloatingMenu
      title="Filter"
      iconClassName={`cx_i--filter cx_i--s cx_i -my-px ${
        isTabletOrMobile || isSmallDesktop ? '!mr-0' : ''
      }`}
      containerClassName="relative"
      titleClassName={`hidden ${isSmallDesktop ? 'md:hidden' : 'md:inline'}`}
    >
      <div>
        {directionsWithUniqVehiclesIds.map(uniqVehicleId => (
          <div
            key={uniqVehicleId.key}
            className="cx_l--flex cx_l--flex-align-center cx_l--flex-justify-start cx_l--width-100 cx_l--margin-top-10 cx_l--margin-left-10"
          >
            <div className="cx_field cx_field--checkbox cx_l--width-auto">
              <input
                className="cx_field__input"
                type="checkbox"
                onChange={() =>
                  handleOnChangeVehicleCheckbox(uniqVehicleId.vehicle_id)
                }
                checked={checkedVehiclesIds.includes(uniqVehicleId.vehicle_id)}
                name={`checkbox-vechicle-${uniqVehicleId.vehicle_name}`}
                id={`checkbox-vechicle-${uniqVehicleId.vehicle_id}`}
              />
              <label
                htmlFor={`checkbox-vechicle-${uniqVehicleId.vehicle_id}`}
                className="cx_field__label cx_t--400 cx_b--cursor-pointer"
              >
                <span className="cx_t cx_t--m cx_t--no-wrap cx_l--margin-right-8 cx_l--overflow-hidden cx_l--max-width-200ch cx_t--text-overflow-ellipsis cx_l--white-space-nowrap">
                  {uniqVehicleId.vehicle_name}
                </span>
                <svg
                  className="cx_i cx_i--xs cx_dropdown__in-trigger--hide"
                  viewBox="0 0 12 12"
                >
                  <circle cx="6" cy="6" r="6" fill={uniqVehicleId.color} />
                </svg>
              </label>
            </div>
          </div>
        ))}
        {anySendToDispatchPins.length > 0 && (
          <div
            key="send-to-dispatch"
            className="cx_l--flex cx_l--flex-align-center cx_l--flex-justify-start cx_l--width-100 cx_l--margin-top-10 cx_l--margin-left-10"
          >
            <div className="cx_field cx_field--checkbox cx_l--width-auto">
              <input
                className="cx_field__input"
                type="checkbox"
                onChange={handleOnChangeSendToDispatch}
                checked={sendToDispatchChecked}
                name="send-to-dispatch"
                id="send-to-dispatch"
              />
              <label
                htmlFor="send-to-dispatch"
                className="cx_field__label cx_t--400 cx_b--cursor-pointer"
              >
                <span className="cx_t cx_t--m cx_t--no-wrap cx_l--margin-right-8 cx_l--overflow-hidden cx_l--max-width-200ch cx_t--text-overflow-ellipsis cx_l--white-space-nowrap">
                  Send To Dispatch
                </span>
              </label>
            </div>
          </div>
        )}
        <div
          onClick={handleSelectAll}
          role="button"
          tabIndex={0}
          className="cx_field__label cx_t--400 cx_b--cursor-pointer cx_l--margin-top-10 cx_l--margin-left-10 cx_l--margin-bottom-5"
        >
          <span className="cx_t cx_t--blue cx_t--m">Select All</span>
        </div>
      </div>
    </FloatingMenu>
  );
};

FilterVehicle.defaultProps = {
  directionsWithVehicles: [],
  checkedVehiclesIds: [],
  setCheckedVehiclesIds: () => {},
  setSendToDispatchChecked: () => {},
  sendToDispatchChecked: true,
  anySendToDispatchPins: []
};

FilterVehicle.propTypes = {
  directionsWithUniqVehiclesIds: PropTypes.array,
  checkedVehiclesIds: PropTypes.array,
  setCheckedVehiclesIds: PropTypes.func,
  setSendToDispatchChecked: PropTypes.func,
  sendToDispatchChecked: PropTypes.bool,
  anySendToDispatchPins: PropTypes.array
};

export default FilterVehicle;
