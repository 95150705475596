import { createSlice } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

const initialState = null;

export const serviceResponsesSlice = createSlice({
  name: 'serviceResponses',
  initialState,
  reducers: {
    addServiceResponse: (state, action) => [
      ...(!isNil(state) ? state : []),
      action.payload
    ]
  }
});

export default serviceResponsesSlice.reducer;
