import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const driversSlice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    setDrivers: (state, action) => action.payload
  }
});

export default driversSlice.reducer;
