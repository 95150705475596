import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({
  isOpen,
  setIsOpen,
  modalCssClasses,
  overlayCssClasses,
  boxCssClasses,
  overlay = true,
  overlayClearsModal = true,
  children,
  onClose
}) => {
  const handleOnClose = e => {
    e.preventDefault();
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  };

  return (
    isOpen && (
      <div
        className={`flex fixed left-0 top-0 w-full h-full flex-col items-center justify-center z-50 ${
          modalCssClasses ? modalCssClasses : ''
        }`}
      >
        {overlay ? (
          <div
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            className={`absolute left-0 top-0 w-full h-full bg-[#06193880] opacity-50 cursor-default ${
              overlayCssClasses ? overlayCssClasses : ''
            }`}
            onClick={overlayClearsModal ? handleOnClose : null}
          />
        ) : null}
        <div
          className={`relative max-w-[572px] w-[95%] p-2 bg-white border-1 border-solid border-white shadow-md rounded-sm ${
            boxCssClasses ? boxCssClasses : ''
          }`}
        >
          {children}
        </div>
      </div>
    )
  );
};

Modal.defaultProps = {
  setIsOpen: () => {}
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  modalCssClasses: PropTypes.string,
  overlayCssClasses: PropTypes.string,
  boxCssClasses: PropTypes.string,
  overlayClearsModal: PropTypes.bool,
  overlay: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func
};

export default Modal;
