import { useDispatch, useSelector } from 'react-redux';
import { userSlice } from '../store/user';
import { includes } from 'lodash';

const useUserStore = () => {
  const dispatch = useDispatch();

  const getUser = useSelector(state => state.user);

  const setUser = user => {
    dispatch(userSlice.actions.setUser(user));
  };

  const isWebAdminUser = getUser?.organization_active
    ? includes(getUser?.connect_roles, 'connect_web_admin')
    : false;

  const isWebUser = getUser?.organization_active
    ? includes(getUser?.connect_roles, 'connect_web') && !isWebAdminUser
    : false;

  const isWebReadOnlyUser = getUser?.organization_active
    ? includes(getUser?.connect_roles, 'connect_web_read_only') &&
      !isWebUser &&
      !isWebAdminUser
    : true;

  return {
    getUser,
    setUser,
    isWebAdminUser,
    isWebUser,
    isWebReadOnlyUser
  };
};

export default useUserStore;
