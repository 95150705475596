import { createSlice } from '@reduxjs/toolkit';
import { eq } from 'lodash';

const initialState = null;

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization: (state, action) => action.payload,
    updateOrganization: (state, action) =>
      eq(state.id, action.payload.id) ? action.payload : state
  }
});

export default organizationSlice.reducer;
