/* eslint-disable no-console */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import regeneratorRuntime from 'regenerator-runtime';
import {
  has,
  isNil,
  isEmpty,
  each,
  map,
  mapKeys,
  some,
  includes,
  startCase
} from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { USER_ROLES } from '../constants';
import ImageUpload from './ImageUpload';

const UserForm = ({
  user,
  userErrors,
  submitDisabled,
  resetPasswordDisabled,
  onSubmit,
  onResetPassword,
  onCancel,
  onChangeInput
}) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    errors,
    setError,
    clearErrors,
    formState
  } = useForm({
    mode: 'onSubmit',
    revalidateMode: 'onBlur'
  });

  useEffect(() => {
    mapKeys(userErrors, (value, key) => {
      setError(key, {
        message: `${startCase(key)} ${value.join(', ')}`
      });
    });
  }, [userErrors]);

  const hasUserPermissions = () => {
    return some(USER_ROLES, role => watch(role.name));
  };

  const validateUserPermissions = () => {
    if (!formState.isSubmitted) return;

    if (hasUserPermissions()) {
      each(USER_ROLES, role => clearErrors([role.name]));
    } else {
      each(USER_ROLES, role =>
        setError(role.name, { message: 'User permissions required' })
      );
    }
  };

  const readOnly = !!user?.deactivated_at;

  const handleOnChangeInput = e => {
    if (onChangeInput) {
      onChangeInput(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={`cx_field cx_field--text ${
          has(errors, 'given_name') ? 'cx_field--danger' : ''
        }`}
      >
        <label htmlFor="given_name" className="cx_field__label">
          First Name
        </label>
        <input
          name="given_name"
          id="given_name"
          defaultValue={user?.given_name}
          ref={register({ required: 'First name is required' })}
          className="cx_field__input cx_l--width-100"
          type="text"
          placeholder="First name"
          disabled={readOnly}
          onChange={handleOnChangeInput}
        />
        <div className="cx_field__messages">
          {has(errors, 'given_name') && (
            <div className="cx_field__message cx_field__message--danger">
              {errors.given_name?.message}
            </div>
          )}
        </div>
      </div>
      <div
        className={`cx_field cx_field--text ${
          has(errors, 'family_name') ? 'cx_field--danger' : ''
        }`}
      >
        <label htmlFor="family_name" className="cx_field__label">
          Last Name
        </label>
        <input
          name="family_name"
          id="family_name"
          defaultValue={user?.family_name}
          ref={register({ required: 'Last name is required' })}
          className="cx_field__input cx_l--width-100"
          type="text"
          placeholder="Last name"
          disabled={readOnly}
          onChange={handleOnChangeInput}
        />
        <div className="cx_field__messages">
          {has(errors, 'family_name') && (
            <div className="cx_field__message cx_field__message--danger">
              {errors.family_name?.message}
            </div>
          )}
        </div>
      </div>
      <div
        className={`cx_field cx_field--text ${
          has(errors, 'email') ? 'cx_field--danger' : ''
        }`}
      >
        <label htmlFor="email" className="cx_field__label">
          Email
        </label>
        <input
          name="email"
          id="email"
          defaultValue={user?.email}
          ref={register({ required: 'Email is required' })}
          className="cx_field__input cx_l--width-100"
          type="email"
          placeholder="Email"
          disabled={!isNil(user?.email) || readOnly}
          onChange={handleOnChangeInput}
        />
        <div className="cx_field__messages">
          {has(errors, 'email') && (
            <div className="cx_field__message cx_field__message--danger">
              {errors.email?.message}
            </div>
          )}
        </div>
      </div>
      <div
        className={`cx_field cx_field--text ${
          has(errors, 'phone_number') ? 'cx_field--danger' : ''
        }`}
      >
        <label htmlFor="phone_number" className="cx_field__label">
          Phone Number
        </label>
        <Controller
          name="phone_number"
          defaultValue={user?.phone_number ?? ''}
          control={control}
          rules={{ required: 'Phone number is required' }}
          render={({ onChange, name, value }) => (
            <InputMask
              type="tel"
              name={name}
              mask="999-999-9999"
              value={value}
              alwaysShowMask={false}
              maskChar={null}
              pattern="^[2-9]\d{2}-\d{3}-\d{4}$"
              placeholder="555-555-5555"
              onChange={e => {
                onChange(e.target.value);
                handleOnChangeInput(e);
              }}
              className="cx_field__input cx_l--width-100"
              disabled={readOnly}
            />
          )}
        />
        <div className="cx_field__messages">
          {has(errors, 'phone_number') && (
            <div className="cx_field__message cx_field__message--danger">
              {errors.phone_number?.message}
            </div>
          )}
        </div>
      </div>
      <div
        className={`cx_field cx_field--text ${
          has(errors, 'connect_web') ? 'cx_field--danger' : ''
        }`}
      >
        <label
          htmlFor="user_permissions"
          className="cx_field__label cx_l--margin-bottom-4"
        >
          User Roles
        </label>
        <div className="cx_l--margin-bottom-24 cx_t--xxs cx_t--600">
          Find permissions for each role in this{' '}
          <a
            className="cx_t--underline cx_t--lightBlue"
            target="_blank"
            rel="noreferrer"
            href="https://support.dispatchit.com/how-to-create-a-new-connect-user-or-driver"
          >
            article
          </a>
        </div>
        <div className="cx_field__checkbox-group">
          {map(USER_ROLES, role => (
            <div
              key={role.name}
              className="cx_field cx_field--checkbox cx_l--margin-bottom-24"
            >
              <input
                name={role.name}
                id={role.name}
                defaultChecked={includes(user?.connect_roles, role.name)}
                className="cx_field__input"
                type="checkbox"
                onChange={validateUserPermissions}
                ref={register({
                  validate: {
                    required: () =>
                      hasUserPermissions() || 'User permissions required'
                  }
                })}
                disabled={readOnly}
              />
              <label htmlFor={role.name} className="cx_field__label">
                <div className="cx_l--width-100 cx_l--margin-left-8">
                  <div className="cx_t--700 cx_l--margin-bottom-8">
                    {role.label}
                  </div>
                  <div className="cx_t--400">{role.description}</div>
                </div>
              </label>
            </div>
          ))}
        </div>
        <div className="cx_field__messages">
          {has(errors, 'connect_web') && (
            <div className="cx_field__message cx_field__message--danger">
              {errors.connect_web?.message}
            </div>
          )}
        </div>
      </div>
      <div className="cx_l--margin-bottom-12">
        <label
          htmlFor="user_photo"
          className="cx_field__label cx_l--margin-bottom-12"
        >
          User Photo (optional)
        </label>
        <Controller
          name="profile_image"
          defaultValue={
            isNil(user?.profile_image) || user.profile_image_is_placeholder
              ? ''
              : user?.profile_image?.url
          }
          control={control}
          render={({ onChange, name, value }) => (
            <ImageUpload
              id={name}
              name={name}
              value={value}
              onChange={onChange}
              disabled={readOnly}
            />
          )}
        />
      </div>
      <div className="cx_l--flex cx_l--flex-align-start">
        <button
          disabled={!isEmpty(errors) || submitDisabled || readOnly}
          className="cx_button cx_button--alpha"
        >
          Save User
        </button>
        {!isNil(onResetPassword) && (
          <button
            disabled={resetPasswordDisabled || readOnly}
            className="cx_button cx_button--beta cx_l--margin-left-16"
            onClick={onResetPassword}
          >
            Password Reset
          </button>
        )}
        {!isNil(onCancel) && (
          <button
            className="cx_button cx_button--beta cx_l--margin-left-16"
            onClick={onCancel}
          >
            Cancel
          </button>
        )}
      </div>
    </form>
  );
};

UserForm.defaultProps = {
  user: null,
  userErrors: {},
  submitDisabled: false,
  resetPasswordDisabled: false,
  onResetPassword: null,
  onCancel: null,
  onChangeInput: null
};

UserForm.propTypes = {
  user: PropTypes.object,
  userErrors: PropTypes.object,
  submitDisabled: PropTypes.bool,
  resetPasswordDisabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func,
  onCancel: PropTypes.func,
  onChangeInput: PropTypes.func
};

export default UserForm;
