import React from 'react';
import PropTypes from 'prop-types';
import TooltipTrigger from 'react-popper-tooltip';

const Tooltip = ({ children, tooltip, hideArrow, ...props }) => (
  <TooltipTrigger
    {...props}
    tooltip={({
      arrowRef,
      tooltipRef,
      getArrowProps,
      getTooltipProps,
      placement
    }) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: 'cx_tooltip-container'
        })}
      >
        {!hideArrow && (
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: 'cx_tooltip-arrow',
              'data-placement': placement
            })}
          />
        )}
        {tooltip}
      </div>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: 'cx_trigger'
        })}
      >
        {children}
      </span>
    )}
  </TooltipTrigger>
);

Tooltip.defaultProps = {
  hideArrow: false
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  hideArrow: PropTypes.bool
};

export default Tooltip;
