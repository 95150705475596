import React from 'react';
import PropTypes from 'prop-types';
import eq from 'lodash/eq';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import mapPinPickup from '../../../assets/mapPinPickup.png';
import mapPinDropOff from '../../../assets/mapPinDropOff.png';

const CirclePin = ({ color }) => {
  return (
    <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter202b2c47762ef597ee5d6e7d5780531c_d)">
        <circle r="15" transform="matrix(-1 0 0 1 19 19)" fill="white" />
        <circle
          r="13.5"
          transform="matrix(-1 0 0 1 19 19)"
          stroke={color}
          strokeWidth="3"
        />
      </g>
      <defs>
        <filter
          id="filter202b2c47762ef597ee5d6e7d5780531c_d"
          x="0"
          y="0"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

CirclePin.propTypes = {
  color: PropTypes.string.isRequired
};

const ZipCodePin = ({ color, radius }) => {
  return (
    <svg
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform={`translate(${radius}, ${radius})`}>
        <circle r={radius} fill={color} fillOpacity="0.2" />
        <circle r={radius / 2} fill="#ff8300" fillOpacity="0.5" />
      </g>
    </svg>
  );
};

ZipCodePin.propTypes = {
  color: PropTypes.string.isRequired,
  radius: PropTypes.number.isRequired
};

const VehiclePin = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 50 50">
      <g filter="url(#filter0_dd)">
        <g filter="url(#filter1_d)">
          <rect
            width="10.059"
            height="8.876"
            x="20.933"
            y="20.076"
            fill={color}
            rx="1"
            transform="rotate(.575 20.933 20.076)"
          />
        </g>
        <g filter="url(#filter2_d)">
          <path
            fill={color}
            d="M31.15 27.871l.043-4.141 1.656.016 1.639 1.829-.024 2.33-3.313-.034z"
          />
          <path
            stroke={color}
            strokeWidth="3"
            d="M31.192 23.73l-.041 4.141 3.313.034.024-2.33-1.639-1.829-1.893-.019"
          />
        </g>
        <g filter="url(#filter3_d)">
          <circle
            cx="30.592"
            cy="30.529"
            r="2.071"
            fill={color}
            transform="rotate(.575 30.592 30.529)"
          />
        </g>
        <g filter="url(#filter4_d)">
          <circle
            cx="23.491"
            cy="30.458"
            r="2.071"
            fill={color}
            transform="rotate(.575 23.491 30.458)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd"
          width="65.177"
          height="65.544"
          x=".844"
          y=".076"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="10"
            result="effect1_dropShadow"
          />
          <feOffset dx="5" dy="8" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix values="0 0 0 0 0.0901961 0 0 0 0 0.168627 0 0 0 0 0.301961 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="10"
            result="effect2_dropShadow"
          />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix values="0 0 0 0 0.0901961 0 0 0 0 0.168627 0 0 0 0 0.301961 0 0 0 0.04 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter1_d"
          width="22.148"
          height="20.976"
          x="14.844"
          y="20.076"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter2_d"
          width="10.385"
          height="11.205"
          x="27.636"
          y="22.215"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter3_d"
          width="8.183"
          height="8.183"
          x="26.5"
          y="28.437"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter4_d"
          width="8.183"
          height="8.183"
          x="19.4"
          y="28.366"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

VehiclePin.propTypes = {
  color: PropTypes.string.isRequired
};

const CustomerPin = ({ color }) => (
  <svg viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g filter="url(#filter1_d_4751_5353)">
        <path
          d="M11.3333 32C11.6667 18.6667 4 20.6667 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 20.3877 11.6667 18.6667 12 32H11.3333Z"
          fill={color}
        />
      </g>
      <circle r="6" transform="matrix(-1 0 0 1 12 12)" fill={color} />
    </g>
    <defs>
      <filter
        id="filter1_d_4751_5353"
        x="0"
        y="0"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4751_5353"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4751_5353"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

CustomerPin.defaultProps = {
  color: '#003A5D'
};

CustomerPin.propTypes = {
  color: PropTypes.string
};

const PointerPin = ({ color, type }) => {
  return (
    <svg viewBox="0 0 50 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd)">
        <path
          d="M24.5833 72C23.7917 39.619 42 44.4762 42 23.4286C42 12.6985 33.4934 4 23 4C12.5066 4 4 12.6985 4 23.4286C4 43.7988 23.7917 39.619 23 72H24.5833Z"
          fill={color}
        />
        <circle cx="23" cy="23" r="15" fill="white" />
        {isNumber(type) && (
          <text
            x="23"
            y="23"
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              color: color,
              fontFamily: "'Inter', sans-serif",
              fontSize: 25
            }}
            fill={color}
          >
            {type}
          </text>
        )}
        {eq(type, 'dispatchDropOff') && (
          <path
            transform="matrix(1 0 0 1 11.5 11.5)"
            d="M21.3034 6.27686C20.8146 4.97521 20.0941 3.84711 19.1936 2.89256C18.2931 1.96694 17.1868 1.2438 15.9004 0.752066C14.6654 0.231405 13.276 0 11.7323 0H4.78555V8.15703L0 9.92149L9.05652 12.9298V6.91322V4.77273H11.7323C12.7872 4.77273 13.7134 4.91736 14.4853 5.23554C15.2057 5.52479 15.8232 5.90083 16.312 6.39256C16.7751 6.8843 17.1353 7.43388 17.3669 8.07025C17.6242 8.76446 17.7528 9.51653 17.7528 10.3843V10.4421C17.7528 11.3099 17.6242 12.062 17.3669 12.7851C17.1096 13.4793 16.7494 14.0289 16.2863 14.5207C15.7974 15.0124 15.18 15.4463 14.4338 15.7355C13.662 16.0537 12.71 16.1983 11.6551 16.1983H9.33953V21H11.6551C13.1988 21 14.6139 20.7397 15.8489 20.2479C17.1353 19.7273 18.2417 19.0041 19.1422 18.0496C20.0684 17.095 20.7888 15.938 21.2777 14.6364C21.7665 13.3347 21.9981 11.9463 21.9981 10.4421V10.4132C22.0238 8.93802 21.7922 7.54959 21.3034 6.27686Z"
            fill={color}
          />
        )}
        {eq(type, 'incompleteDropOff') && (
          <>
            <path
              transform="matrix(1.5 0 0 1.5 21.5 15.5)"
              d="M2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1V6C0 6.55228 0.447715 7 1 7C1.55228 7 2 6.55228 2 6V1Z"
              fill={color}
            />
            <path
              transform="matrix(1.5 0 0 1.5 21.5 15.5)"
              d="M1 10C1.55228 10 2 9.55228 2 9C2 8.44772 1.55228 8 1 8C0.447715 8 0 8.44772 0 9C0 9.55228 0.447715 10 1 10Z"
              fill={color}
            />
          </>
        )}
      </g>
      <defs>
        <filter
          id="filter0_dd"
          x="0"
          y="0"
          width="50"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

PointerPin.defaultProps = {
  type: null
};

PointerPin.propTypes = {
  color: PropTypes.string.isRequired,
  type: PropTypes.oneOfType([
    PropTypes.oneOf([
      'pickup',
      'dropOff',
      'incompleteDropOff',
      'dispatchDropOff'
    ]),
    PropTypes.number
  ])
};

const Tooltip = ({ tooltip, tooltipClassName }) => {
  return (
    <div className={`cx_map-pin__tooltip ${tooltipClassName}`}>{tooltip}</div>
  );
};
Tooltip.defaultProps = {
  tooltipClassName: ''
};

Tooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
  tooltipClassName: PropTypes.string
};

const MapPin = props => {
  const {
    children,
    type,
    color,
    highlight,
    tooltip,
    onHoverStateChange,
    radius,
    className,
    driverOrders
  } = props;

  const handleMouseEnter = () => {
    if (isNil(onHoverStateChange)) return;

    onHoverStateChange(true);
  };

  const handleMouseLeave = () => {
    if (isNil(onHoverStateChange)) return;

    onHoverStateChange(false);
  };

  if (isNil(type))
    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
    );

  if (eq(type, 'zipCode')) {
    return (
      <div
        style={{
          width: `${radius * 2}px`,
          height: `${radius * 2}px`,
          position: 'relative'
        }}
        className={className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {tooltip && (
          <Tooltip
            tooltip={tooltip}
            tooltipClassName="cx_map-pin-zipCode__tooltip"
          />
        )}
        <ZipCodePin color={color} radius={radius} />
      </div>
    );
  }

  if (eq(type, 'customer')) {
    return (
      <div
        style={{
          width: highlight ? 34 : 21,
          height: highlight ? 34 : 21,
          position: 'relative'
        }}
        className={className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {tooltip && (
          <Tooltip
            tooltip={tooltip}
            tooltipClassName="cx_map-pin-customer__tooltip"
          />
        )}
        <CustomerPin color={color} />
      </div>
    );
  }

  if (eq(type, 'pickup') && driverOrders) {
    return (
      <img
        src={mapPinPickup}
        alt="Pickup Location"
        className={className}
        style={{
          width: 47,
          height: 73,
          transformOrigin: 'left bottom',
          transform: 'translate(calc(-50% + 2px) , calc(-100% + 8px))'
        }}
      />
    );
  }

  if (eq(type, 'pickup') && !driverOrders) {
    return (
      <div
        style={{
          width: highlight ? 34 : 21,
          height: highlight ? 34 : 21,
          transform: 'translate(-45%, -45%)'
        }}
        className={className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {highlight && tooltip && <Tooltip tooltip={tooltip} />}
        <CirclePin color={color} type={type} />
      </div>
    );
  }

  if (eq(type, 'dropoff') && driverOrders) {
    return (
      <img
        src={mapPinDropOff}
        alt="Drop-Off Location"
        className={className}
        style={{
          width: 47,
          height: 73,
          transformOrigin: 'left bottom',
          transform: 'translate(calc(-50% + 2px) , calc(-100% + 8px))'
        }}
      />
    );
  }

  if (eq(type, 'vehicle')) {
    return (
      <div
        style={{
          width: 67,
          height: 67,
          transform: 'translate(-50%, -50%)'
        }}
        className={className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <VehiclePin color={color} />
      </div>
    );
  }

  return (
    <div
      style={{
        width: highlight ? 33 : 20,
        height: highlight ? 58 : 35,
        transform: 'translate(-46%, -85%)'
      }}
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {highlight && tooltip && <Tooltip tooltip={tooltip} />}
      <PointerPin color={color} type={type} />
    </div>
  );
};

MapPin.defaultProps = {
  type: null,
  color: '#003A5D',
  tooltip: null,
  highlight: false,
  onHoverStateChange: null,
  className: '',
  driverOrders: false
};

MapPin.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOfType([
    PropTypes.oneOf([
      'pickup',
      'dropOff',
      'dropoff',
      'incompleteDropOff',
      'dispatchDropOff',
      'vehicle',
      'zipCode',
      'customer'
    ]),
    PropTypes.number
  ]),
  color: PropTypes.string,
  tooltip: PropTypes.string,
  highlight: PropTypes.bool,
  onHoverStateChange: PropTypes.func,
  radius: PropTypes.number,
  className: PropTypes.string,
  driverOrders: PropTypes.bool
};

// Prevent unnecessary API calls by checking if no props have changed.
// If props are all the same this component shouldn't update.
// NOTE: Elements and functions need to be excluded.
export default React.memo(MapPin, (prevProps, nextProps) => {
  const excludedProps = ['onHoverStateChange'];
  const prev = omit(prevProps, excludedProps);
  const next = omit(nextProps, excludedProps);
  return isEqual(prev, next);
});
