/* eslint-disable import/no-unresolved */
import getAddressObjectFromPlace from '../../googleUtils/getAddressObjectFromPlace';
import queryString from 'query-string';
import {
  format as datetimeFormat,
  parseISO,
  isPast,
  roundToNearestMinutes
} from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import includes from 'lodash/includes';
import find from 'lodash/find';
import eq from 'lodash/eq';
import round from 'lodash/round';
import { renderCoordinatesString } from '../utils';

export const processPlace = (
  inputValue,
  place,
  initialLoad = false,
  manualLocation = false
) => {
  const getGeo = place => {
    if (place.geometry) {
      return {
        lat: place.geometry.location?.lat(),
        lng: place.geometry.location?.lng()
      };
    } else {
      return { lat: null, lng: null };
    }
  };

  const address = getAddressObjectFromPlace(place);
  const { lat, lng } = getGeo(place);
  const fields = {
    description: inputValue,
    google_place_id: place.place_id ? place.place_id.toString() : null,
    geo_lat: lat ? lat.toString() : null,
    geo_lng: lng ? lng.toString() : null,
    country: address.country,
    iso_country_code: address.country,
    name: address.name,
    phone: address.phone_number,
    street: address.street,
    neighborhood: address.neighborhood,
    city: address.city,
    state: address.state,
    postal_code: address.zip,
    default_notes: place.default_notes,
    saved_location_label: place.saved_location_label,
    manual_location: initialLoad ? manualLocation : false
  };

  return fields;
};

export const formatLocationInputValue = address =>
  address
    ? address.manual_location
      ? address.description?.includes('near')
        ? address.description
        : `${renderCoordinatesString(address)} (near ${address.description})`
      : `${address.street}, ${address.city}`
    : '';

const formatQueryDate = date => {
  const parsed = date ? parseISO(date) : null;

  if (isNil(parsed) || isPast(parsed)) return null;

  return datetimeFormat(parsed, 'yyyy-MM-dd');
};

const roundFormatQueryTime = datetime => {
  const parsed = datetime
    ? roundToNearestMinutes(parseISO(datetime), { nearestTo: 30 })
    : null;

  if (isNil(parsed) || isPast(parsed)) return null;

  return parsed.toString();
};

export const queryParamParser = queryData => {
  let parsed = queryData;
  const dropOffAddressPresent =
    !isEmpty(queryData?.['drop_off_address[street]']) &&
    !isEmpty(queryData?.['drop_off_address[city]']) &&
    !isEmpty(queryData?.['drop_off_address[state]']) &&
    !isEmpty(queryData?.['drop_off_address[postal_code]']) &&
    !isEmpty(queryData?.['drop_off_address[country]']);

  parsed['drop_off_address'] = dropOffAddressPresent
    ? {
        street: queryData['drop_off_address[street]'],
        city: queryData['drop_off_address[city]'],
        state: queryData['drop_off_address[state]'],
        postal_code: queryData['drop_off_address[postal_code]'],
        country: queryData['drop_off_address[country]'],
        iso_country_code: queryData['drop_off_address[country]'],
        google_place_id: queryData['drop_off_address[google_place_id]'] ?? '',
        geo_lat: queryData['drop_off_address[geo_lat]'] ?? '',
        geo_lng: queryData['drop_off_address[geo_lng]'] ?? '',
        description: queryData['drop_off_address[description]'] ?? '',
        manual_location: eq(
          queryData['drop_off_address[manual_location]'],
          'true'
        )
      }
    : null;

  const pickupAddressPresent =
    !isEmpty(queryData?.['pickup_address[street]']) &&
    !isEmpty(queryData?.['pickup_address[city]']) &&
    !isEmpty(queryData?.['pickup_address[state]']) &&
    !isEmpty(queryData?.['pickup_address[postal_code]']) &&
    !isEmpty(queryData?.['pickup_address[country]']);

  parsed['pickup_address'] = pickupAddressPresent
    ? {
        street: queryData['pickup_address[street]'],
        city: queryData['pickup_address[city]'],
        state: queryData['pickup_address[state]'],
        postal_code: queryData['pickup_address[postal_code]'],
        country: queryData['pickup_address[country]'],
        iso_country_code: queryData['pickup_address[country]'],
        google_place_id: queryData['pickup_address[google_place_id]'] ?? '',
        geo_lat: queryData['pickup_address[geo_lat]'] ?? '',
        geo_lng: queryData['pickup_address[geo_lng]'] ?? '',
        description: queryData['pickup_address[description]'] ?? '',
        manual_location: eq(
          queryData['pickup_address[manual_location]'],
          'true'
        )
      }
    : null;

  parsed['pickup_date'] = formatQueryDate(
    queryData?.['pickup_window_end_at'] ?? queryData?.['date']
  );
  parsed['pickup_window_start_at'] = roundFormatQueryTime(
    queryData?.['pickup_window_start_at']
  );
  parsed['pickup_window_end_at'] = roundFormatQueryTime(
    queryData?.['pickup_window_end_at']
  );
  parsed['drop_off_window_start_at'] = roundFormatQueryTime(
    queryData?.['drop_off_window_start_at']
  );
  parsed['drop_off_window_end_at'] = roundFormatQueryTime(
    queryData?.['drop_off_window_end_at']
  );

  return parsed;
};

export const dateQueryString = search => {
  return queryString.stringify(pick(queryString.parse(search), 'date'));
};

export const DEFAULT_TIME_ZONE = 'Central Time (US & Canada)';

export const TIME_ZONE_OPTIONS = {
  'Pacific Time (US & Canada)': 'PST',
  'Mountain Time (US & Canada)': 'MST',
  'Central Time (US & Canada)': 'CST',
  'Eastern Time (US & Canada)': 'EST',
  'Puerto Rico': 'AST'
};

export const TIME_ZONE_ALIAS = {
  'Pacific Time (US & Canada)': 'America/Los_Angeles',
  'Mountain Time (US & Canada)': 'America/Denver',
  'Central Time (US & Canada)': 'America/Chicago',
  'Eastern Time (US & Canada)': 'America/New_York',
  'Puerto Rico': 'America/Puerto_Rico'
};

// Eg. 'America/Chicago'
export const CURRENT_TIME_ZONE_ALIAS = Intl.DateTimeFormat().resolvedOptions()
  .timeZone;

// Eg. 'Central Time (US & Canada)'
export const CURRENT_TIME_ZONE = find(Object.keys(TIME_ZONE_ALIAS), key =>
  eq(TIME_ZONE_ALIAS[key], CURRENT_TIME_ZONE_ALIAS)
);

// Eg. 'CST'
export const CURRENT_TIME_ZONE_ABBR =
  TIME_ZONE_OPTIONS[CURRENT_TIME_ZONE] ?? '';

export const utcTime = (time, tz = DEFAULT_TIME_ZONE) =>
  zonedTimeToUtc(time, TIME_ZONE_ALIAS[tz]);

export const zonedTime = (time, tz = DEFAULT_TIME_ZONE) =>
  utcToZonedTime(time, TIME_ZONE_ALIAS[tz]);

export const abbrTimeZone = time_zone => {
  let tz = [
    ['PST', 'US/Pacific', 'Pacific Time (US & Canada)'],
    ['MST', 'US/Mountain', 'Mountain Time (US & Canada)'],
    ['CST', 'US/Central', 'Central Time (US & Canada)'],
    ['EST', 'US/Eastern', 'Eastern Time (US & Canada)']
  ];
  let tzAbbr;

  tz.forEach(tzi => {
    if (includes(tzi, time_zone)) {
      tzAbbr = tzi[0];
    }
  });
  return tzAbbr;
};

export const NOTIFICATIONS_MAPPING = {
  both: { email_notifications: true, sms_notifications: true },
  email: { email_notifications: true, sms_notifications: false },
  sms: { email_notifications: false, sms_notifications: true },
  none: { email_notifications: false, sms_notifications: false }
};

export const genRandomHex = size =>
  Array.from(Array(size), () =>
    Math.floor(Math.random() * 16).toString(16)
  ).join('');

export const numberToCurrency = number =>
  number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

export const roundCoord = coord => round(coord, 6).toFixed(6);
