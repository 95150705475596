/* eslint-disable import/no-unresolved */

import { combineReducers } from '@reduxjs/toolkit';
import uiReducer from './ui';
import userReducer from './user';
import usersReducer from './users';
import organizationReducer from './organization';
import driversReducer from './drivers';
import vehiclesReducer from './vehicles';
import deliveriesReducer from './deliveries';
import stopsReducer from './stops';
import stopsUiReducer from './stopsUi';
import manifestsReducer from './manifests';
import savedManifestsReducer from './savedManifests';
import featureFlagsReducer from './featureFlags';
import serviceResponsesReducer from './serviceResponses';
import savedCustomersReducer from './savedCustomers';
import deliveriesCurrentCsvImportReducer from './deliveriesCurrentCsvImport';

const rootReducer = combineReducers({
  ui: uiReducer,
  user: userReducer,
  users: usersReducer,
  organization: organizationReducer,
  vehicles: vehiclesReducer,
  drivers: driversReducer,
  deliveries: deliveriesReducer,
  stops: stopsReducer,
  stopsUi: stopsUiReducer,
  manifests: manifestsReducer,
  savedManifests: savedManifestsReducer,
  featureFlags: featureFlagsReducer,
  serviceResponses: serviceResponsesReducer,
  savedCustomers: savedCustomersReducer,
  deliveriesCurrentCsvImport: deliveriesCurrentCsvImportReducer
});

export default rootReducer;
