import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const deliveriesCurrentCsvImportSlice = createSlice({
  name: 'deliveriesCurrentCsvImport',
  initialState,
  reducers: {
    setImport: (state, action) => action.payload
  }
});

export default deliveriesCurrentCsvImportSlice.reducer;
