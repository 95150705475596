import { createSlice } from '@reduxjs/toolkit';
import eq from 'lodash/eq';
import lt from 'lodash/lt';
import lte from 'lodash/lte';
import gt from 'lodash/gt';
import gte from 'lodash/gte';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';

const initialState = null;

export const stopsUiSlice = createSlice({
  name: 'stopsUi',
  initialState,
  reducers: {
    setStops: (state, action) => action.payload,
    incrementStop: (state, action) => {
      const { manifest_id, sort_key } = action.payload;

      return map(state, stop => {
        if (eq(stop.manifest_id, manifest_id) && eq(stop.sort_key, sort_key)) {
          return {
            ...stop,
            sort_key: stop.sort_key + 1
          };
        }

        if (
          eq(stop.manifest_id, manifest_id) &&
          eq(stop.sort_key, sort_key + 1)
        ) {
          return {
            ...stop,
            sort_key: stop.sort_key - 1
          };
        }

        return stop;
      });
    },
    decrementStop: (state, action) => {
      const { manifest_id, sort_key } = action.payload;

      return map(state, stop => {
        if (eq(stop.manifest_id, manifest_id) && eq(stop.sort_key, sort_key)) {
          return {
            ...stop,
            sort_key: stop.sort_key - 1
          };
        }

        if (
          eq(stop.manifest_id, manifest_id) &&
          eq(stop.sort_key, sort_key - 1)
        ) {
          return {
            ...stop,
            sort_key: stop.sort_key + 1
          };
        }

        return stop;
      });
    },
    reorderStops: (state, action) => {
      const { startStop, endStop } = action.payload;
      const { manifest_id, sort_key } = startStop;
      const sortKeyDiff = endStop.sort_key - startStop.sort_key;

      return map(state, stop => {
        if (eq(stop.manifest_id, manifest_id) && eq(stop.sort_key, sort_key)) {
          return {
            ...stop,
            sort_key: stop.sort_key + sortKeyDiff
          };
        }

        if (
          eq(stop.manifest_id, manifest_id) &&
          gt(stop.sort_key, sort_key) &&
          lte(stop.sort_key, sort_key + sortKeyDiff)
        ) {
          return {
            ...stop,
            sort_key: stop.sort_key - 1
          };
        }

        if (
          eq(stop.manifest_id, manifest_id) &&
          lt(stop.sort_key, sort_key) &&
          gte(stop.sort_key, sort_key + sortKeyDiff)
        ) {
          return {
            ...stop,
            sort_key: stop.sort_key + 1
          };
        }

        return stop;
      });
    },
    updateStops: (state, action) =>
      map(state, stop => find(action.payload, { id: stop.id }) || stop),
    setStopsBy: (state, action) => [
      ...filter(
        state,
        stop => !eq(stop[action.payload.key], action.payload.value)
      ),
      ...action.payload.stops
    ],
    addStops: (state, action) => [...state, ...action.payload],

    setHighlightedStops: (state, action) => {
      const newHighlightedStops = action.payload;
      const newHighlightedStopsIds = map(newHighlightedStops, 'id');

      return map(state, stop => {
        if (newHighlightedStopsIds.includes(stop.id)) {
          return {
            ...stop,
            highlighted: true
          };
        }

        return {
          ...stop,
          highlighted: false
        };
      });
    }
  }
});

export default stopsUiSlice.reducer;
