import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useUIStore from '../hooks/uiStore';
import eq from 'lodash/eq';
import find from 'lodash/find';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import lowerCase from 'lodash/lowerCase';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import useClickAway from '../hooks/clickaway';

const DropDown = ({
  id,
  name,
  title,
  className,
  outline,
  placeholder,
  searchPlaceholder,
  onChange,
  onSearchChange,
  options,
  inputValue,
  readOnly,
  isLoading,
  dataCy
}) => {
  const { getDropdown: dropdown, setDropdown, clearDropdown } = useUIStore();
  const [triggerContent, setTriggerContent] = useState(null);
  const [value, setValue] = useState(inputValue || '');
  const [searchInput, setSearchInput] = useState('');
  const searchInputRef = useRef(null);
  const dropdownList = useRef(null);
  const dropdownContainer = useRef(null);
  const isSearchable = !isNil(onSearchChange);

  const handleTriggerClick = event => {
    event.preventDefault();

    if (readOnly || isLoading) return;

    if (isNil(dropdown) || !eq(dropdown.id, id)) {
      setDropdown({ id });
      return;
    }

    clearDropdown();
  };

  const handleTriggerKeyPress = event => {
    event.preventDefault();

    if (!eq(event.key, 'Enter')) return;

    if (isNil(dropdown) || !eq(dropdown.id, id)) {
      setDropdown({ id });
      return;
    }

    clearDropdown();
  };

  const handleItemClick = key => {
    setValue(key);
    clearDropdown();
  };

  const handleEscape = useCallback(
    event => {
      if (!eq(event.key, 'Escape')) return;
      clearDropdown();
    },
    [setDropdown]
  );

  const handleSearchChange = event => {
    setSearchInput(event.target.value);
    onSearchChange(event.target.value);
  };

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (isNil(onChange) || !isFunction(onChange)) return;
    if (eq(value, inputValue)) return;
    onChange(value, setValue);
  }, [value]);

  useEffect(() => {
    if (isNil(dropdown) || !has(dropdownContainer, 'current')) return;
    dropdownContainer.current.blur();
  }, [dropdown]);

  useEffect(() => {
    if (!isNil(dropdown)) {
      window.addEventListener('keyup', handleEscape);
      return () => window.removeEventListener('keyup', handleEscape);
    }
  }, [dropdown]);

  useEffect(() => {
    if (eq(dropdown?.id, id) && isSearchable) {
      setSearchInput('');
      onSearchChange('');
      searchInputRef?.current?.focus();
    }
  }, [dropdown]);

  useClickAway(dropdownContainer, () => {
    if (isNil(dropdown) || !eq(dropdown.id, id)) return;
    clearDropdown();
  });

  useEffect(() => {
    if (isNil(dropdownList?.current)) return;

    const item = find(dropdownList.current.children, { id: value })?.children[0]
      ?.children[0];

    if (isNil(item)) {
      setTriggerContent(null);
      return;
    }

    setTriggerContent({
      tagName: lowerCase(item.tagName),
      className: item.className,
      innerHTML: item.innerHTML
    });
  }, [dropdownList, value]);

  const openClass =
    !isNil(dropdown) && eq(dropdown.id, id) ? 'cx_dropdown--open' : '';
  const readOnlyClass = readOnly ? 'cx_dropdown--readonly' : '';
  const isLoadingClass = isLoading ? 'cx_dropdown--standard-isloading' : '';

  const grandchildContainsDisabledClass = key => {
    return includes(
      document
        .getElementById(key)
        ?.childNodes[0]?.childNodes[0]?.className.split(' '),
      'cx_is-disabled'
    );
  };

  return (
    <div
      ref={dropdownContainer}
      className={`cx_dropdown ${openClass} ${readOnlyClass} ${isLoadingClass} ${className}`}
    >
      <input
        id={name}
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        hidden={true}
      />
      <button
        id={`${name}__trigger`}
        onKeyPress={handleTriggerKeyPress}
        onClick={handleTriggerClick}
        className={`cx_dropdown__trigger ${
          outline ? 'cx_l--padding-top-5 cx_l--padding-bottom-5' : ''
        }`}
        data-cy={dataCy}
      >
        {isNil(triggerContent)
          ? placeholder || <>Select</>
          : React.createElement(triggerContent.tagName, {
              className: triggerContent.className,
              dangerouslySetInnerHTML: { __html: triggerContent.innerHTML }
            })}
      </button>
      {!isNil(options) && (
        <div id={`${name}__list`} className="cx_dropdown__list">
          {title && (
            <div className="cx_t--xxs cx_t--700 cx_t--gray cx_l--padding-left-12 cx_l--padding-top-12">
              {title}
            </div>
          )}
          {isSearchable && (
            <div className="cx_field--text cx_l--padding-top-12 cx_l--padding-left-12 cx_l--padding-right-12 cx_l--padding-bottom-4">
              <input
                ref={searchInputRef}
                className="cx_field__input cx_c--border-lightGray"
                placeholder={searchPlaceholder}
                onChange={handleSearchChange}
                value={searchInput}
              />
            </div>
          )}
          {isEmpty(options) ? (
            <div className="w-80 cx_l--padding-top-10 cx_l--padding-bottom-10 cx_l--padding-left-12 cx_l--margin-bottom-4 cx_t--xs cx_t--gray">
              No results found
            </div>
          ) : (
            <ul
              ref={dropdownList}
              style={{
                minWidth: `${dropdownContainer?.current?.offsetWidth}px`
              }}
              className={`cx_l--overflow-auto ${
                isSearchable ? 'max-h-[270px]' : 'max-h-[163px]'
              }`}
              data-cy={`${dataCy}-list`}
            >
              {map(
                sortBy(Object.keys(options), i => !eq(i, value)),
                key => (
                  <li
                    id={key}
                    key={key}
                    className={`cx_dropdown__item ${
                      eq(key, value) ? 'cx_dropdown__item--selected' : ''
                    }`}
                  >
                    <button
                      disabled={grandchildContainsDisabledClass(key)}
                      onClick={event => {
                        event.preventDefault();
                        handleItemClick(key);
                      }}
                    >
                      {options[key]}
                    </button>
                  </li>
                )
              )}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

DropDown.defaultProps = {
  searchPlaceholder: 'Search...'
};

DropDown.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.element,
  searchPlaceholder: PropTypes.string,
  onChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  options: PropTypes.objectOf(PropTypes.element),
  inputValue: PropTypes.string,
  readOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  dataCy: PropTypes.string,
  outline: PropTypes.bool
};

export default DropDown;
