import { createSlice } from '@reduxjs/toolkit';
import { eq, filter, find, map } from 'lodash';

const initialState = null;

export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    setVehicles: (state, action) => action.payload,
    addVehicle: (state, action) => [...state, action.payload],
    updateVehicles: (state, action) =>
      map(
        state,
        vehicle => find(action.payload, { id: vehicle.id }) || vehicle
      ),
    updateVehicle: (state, action) =>
      map(state, vehicle =>
        eq(vehicle.id, action.payload.id) ? action.payload : vehicle
      ),
    removeVehicle: (state, action) =>
      filter(state, i => !eq(i.id, action.payload.id))
  }
});

export default vehiclesSlice.reducer;
