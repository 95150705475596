/* eslint-disable import/no-unresolved */

import { configureStore } from '@reduxjs/toolkit';
import getCustomMiddleware from './middleware';
import rootReducer from './root';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat(getCustomMiddleware())
});

export default store;
