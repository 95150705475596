import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useClickAway from '../hooks/clickaway';

const FloatingMenu = ({
  children,
  iconClassName,
  title,
  buttonClassName,
  containerClassName,
  iconLeft,
  floatingMenuClassName,
  titleClassName
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const floatingMenu = useRef(null);
  const toggleOpen = () => setIsOpen(prevIsOpen => !prevIsOpen);

  useClickAway(floatingMenu, e => {
    if (isOpen && !buttonRef.current.contains(e.target)) toggleOpen();
  });

  return (
    <div className={containerClassName}>
      <button ref={buttonRef} className={buttonClassName} onClick={toggleOpen}>
        {iconLeft && <span className={iconClassName} />}
        <span className={titleClassName}>{title}</span>
        {!iconLeft && <span className={iconClassName} />}
      </button>
      <div className={floatingMenuClassName} ref={floatingMenu}>
        {isOpen && children}
      </div>
    </div>
  );
};

FloatingMenu.defaultProps = {
  iconLeft: true,
  iconClassName: 'cx_i--s cx_i',
  buttonClassName:
    'cx_button cx_button--beta cx_l--flex cx_l--flex-align-center',
  floatingMenuClassName: 'cx_floating-menu',
  titleClassName: 'inline'
};

FloatingMenu.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  iconLeft: PropTypes.bool,
  iconClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  floatingMenuClassName: PropTypes.string,
  titleClassName: PropTypes.string
};

export default FloatingMenu;
