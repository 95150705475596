import { eq } from 'lodash';
import React from 'react';
import { ROUTES } from '../constants';
import { uiSlice } from './ui';

const { NOT_FOUND, FORBIDDEN } = ROUTES;

export default () => {
  const onAddServiceResponse = store => next => action => {
    if (eq(action?.payload?.status, 403)) {
      if (
        eq(action.payload.code, 'unpaid_subscription') ||
        eq(action.payload.body?.code, 'unpaid_subscription')
      ) {
        store.dispatch(uiSlice.actions.setIsSubscriptionUnpaid(true));
      } else {
        return (window.location = FORBIDDEN());
      }
    }

    if (eq(action?.payload?.status, 404)) {
      return (window.location = NOT_FOUND());
    }

    if (!eq(action.type, 'serviceResponses/addServiceResponse'))
      return next(action);

    // The control structure below should eventually be partialed out
    // in a separate file
    const payloadMatches = payloadModel =>
      payloadModel.fromPath.test(action.payload.fromPath) &&
      eq(payloadModel.method, action.payload.method) &&
      eq(payloadModel.status, action.payload.status) &&
      payloadModel.url.test(action.payload.url);

    if (
      payloadMatches({
        url: /\/organizations\/[0-9]/,
        method: 'PUT',
        status: 200,
        fromPath: /\/connect\/settings/
      })
    ) {
      store.dispatch(
        uiSlice.actions.addToast({
          id: `org-settings-saved`,
          type: 'success',
          message: {
            title: 'Your updates have been saved.'
          }
        })
      );
    }

    if (
      payloadMatches({
        url: /\/send_to_driver/,
        method: 'PUT',
        status: 200,
        fromPath: /\/connect/
      })
    ) {
      store.dispatch(
        uiSlice.actions.addToast({
          id: `send-to-vehicle-saved`,
          type: 'success',
          message: {
            title: `Route for ${action.payload.body?.vehicle?.name} has been sent to ${action.payload.body?.vehicle?.driver_name}.`
          }
        })
      );
    }

    if (
      payloadMatches({
        url: /\/reset_password/,
        method: 'PUT',
        status: 200,
        fromPath: /\/connect\/settings/
      })
    ) {
      store.dispatch(
        uiSlice.actions.addToast({
          id: `reset-password`,
          type: 'success',
          message: {
            title: 'Password reset email sent.'
          }
        })
      );
    }

    if (
      payloadMatches({
        url: /\/saved_customers/,
        method: 'POST',
        status: 200,
        fromPath: /\/connect\/address_book/
      })
    ) {
      store.dispatch(
        uiSlice.actions.addToast({
          id: `add-saved-customer`,
          type: 'success',
          message: {
            title: 'Your customer has been saved.'
          }
        })
      );
    }

    if (
      payloadMatches({
        url: /\/deliveries\/prefill_organization_deliveries/,
        method: 'PUT',
        status: 200,
        fromPath: /\/connect/
      })
    ) {
      store.dispatch(
        uiSlice.actions.addToast({
          id: `prefill-organization-deliveries`,
          type: 'success',
          message: {
            title: 'Your demo deliveries have been created.'
          }
        })
      );
    }

    if (
      payloadMatches({
        url: /\/assign_delivery_to_vehicle/,
        method: 'PUT',
        status: 200,
        fromPath: /\/connect/
      })
    ) {
      store.dispatch(
        uiSlice.actions.addToast({
          id: `assign-delivery-to-vehicle`,
          type: 'success',
          message: {
            title: `Delivery successfully assigned to "${action.payload.body?.vehicle?.name}"`
          }
        })
      );
    }

    if (
      payloadMatches({
        url: /\/users\/[0-9]+\/activate/,
        method: 'PUT',
        status: 200,
        fromPath: /\/connect\/settings\/users\/[0-9]+/
      })
    ) {
      store.dispatch(
        uiSlice.actions.addToast({
          id: `activate-account`,
          type: 'success',
          message: {
            title: 'Account Activated',
            content: (
              <>
                Account for <b>{action.payload.body?.name}</b> was successfully
                activated.
              </>
            )
          }
        })
      );
    }

    if (
      payloadMatches({
        url: /\/saved_manifests/,
        method: 'DELETE',
        status: 200,
        fromPath: /\/connect/
      })
    ) {
      store.dispatch(
        uiSlice.actions.addToast({
          id: `deleted-saved-route`,
          type: 'success',
          message: {
            content: (
              <>
                <b>
                  <q>{action.payload.body.name.split(' - deleted at')[0]}</q>
                </b>{' '}
                was deleted from your list of saved routes.
              </>
            )
          }
        })
      );
    }

    if (
      payloadMatches({
        url: /\/complete_will_call_delivery\/[0-9]+/,
        method: 'PUT',
        status: 200,
        fromPath: /\/connect/
      })
    ) {
      store.dispatch(
        uiSlice.actions.addToast({
          id: `complete-will-call-delivery`,
          type: 'success',
          message: {
            title: `Order marked as "Complete"`
          }
        })
      );
    }

    if (
      payloadMatches({
        url: /\/will_call_notifications\/notify_all/,
        method: 'PUT',
        status: 200,
        fromPath: /\/connect/
      })
    ) {
      store.dispatch(
        uiSlice.actions.addToast({
          id: `will-call-deliveries-notified`,
          type: 'success',
          message: {
            title: (() => {
              const notifiedCount =
                action.payload.body?.deliveries?.filter(
                  delivery => !!delivery.notified_at
                )?.length ?? 0;

              return notifiedCount === 1
                ? 'Notification for 1 order sent'
                : `Notifications for ${notifiedCount} orders sent`;
            })()
          }
        })
      );
    }

    if (
      payloadMatches({
        url: /\/will_call_notifications\/[0-9]+\/notify/,
        method: 'PUT',
        status: 200,
        fromPath: /\/connect/
      })
    ) {
      store.dispatch(
        uiSlice.actions.addToast({
          id: `will-call-delivery-notified`,
          type: 'success',
          message: {
            title: 'Notification Sent'
          }
        })
      );
    }

    if (
      payloadMatches({
        url: /\/complete_delivery\/[0-9]+/,
        method: 'PUT',
        status: 200,
        fromPath: /\/connect/
      })
    ) {
      store.dispatch(
        uiSlice.actions.addToast({
          id: `complete-delivery`,
          type: 'success',
          message: {
            title: `Order marked as "Complete"`
          }
        })
      );
    }

    if (
      eq(action.payload.status, 401) &&
      eq(action.payload.body?.code, 'not_authorized')
    ) {
      window.location.reload();
    }

    return next(action);
  };

  return [onAddServiceResponse];
};
