/* eslint-disable import/no-unresolved */
import { createSlice } from '@reduxjs/toolkit';
import { filter, eq } from 'lodash';

const initialState = {
  toasts: [],
  persisted: true,
  unsavedForms: [],
  modal: null,
  dropdown: null,
  flyout: null,
  preview: null,
  persistedRoutes: {},
  loading: false,
  hovered: {
    pickup: null,
    dropOff: null,
    stop: null
  },
  requestsInProgress: {},
  pickupRepositioning: null,
  dropOffRepositioning: null,
  oneLocationOnlyRepositioning: null,
  mapCenter: null,
  isSubscriptionUnpaid: false,
  waypointDragEvents: {
    slideBack: false,
    positionIsInvalid: false,
    sourceDroppableId: null,
    destinationDroppableId: null,
    loadingDeliveries: false
  }
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setWaypointDragEvent: (state, action) => {
      state.waypointDragEvents[action.payload.key] = action.payload.value;
    },
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    clearModal: state => {
      state.modal = null;
    },
    setDropdown: (state, action) => {
      state.dropdown = action.payload;
    },
    clearDropdown: state => {
      state.dropdown = null;
    },
    setFlyout: (state, action) => {
      state.flyout = action.payload;
    },
    clearFlyout: state => {
      state.flyout = null;
    },
    setPreview: (state, action) => {
      state.preview = action.payload;
    },
    clearPreview: state => {
      state.preview = null;
    },
    setPersisted: (state, action) => {
      state.persisted = action.payload;
    },
    addUnsavedForm: (state, action) => ({
      ...state,
      unsavedForms: [...state.unsavedForms, action.payload]
    }),
    removeUnsavedForm: (state, action) => ({
      ...state,
      unsavedForms: filter(
        state.unsavedForms,
        formId => !eq(formId, action.payload)
      )
    }),
    addToast: (state, action) => {
      state.toasts = [
        ...filter(state.toasts, e => !eq(e.id, action.payload.id)),
        action.payload
      ];
    },
    removeToast: (state, action) => {
      state.toasts = [
        ...filter(state.toasts, e => !eq(e.id, action.payload.id))
      ];
    },
    clearToasts: state => {
      state.toasts = [];
    },
    setPersistedRoute: (state, action) => ({
      ...state,
      persistedRoutes: {
        ...state.persistedRoutes,
        [action.payload.key]: action.payload.persisted
      }
    }),
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setHovered: (state, action) => {
      state.hovered = {
        ...state.hovered,
        [action.payload.type]: action.payload.id
      };
    },
    setRequestsInProgress: (state, action) => {
      state.requestsInProgress = {
        ...state.requestsInProgress,
        [action.payload.request]: action.payload.value
      };
    },
    setAuthenticityToken: (state, action) => {
      state.authenticityToken = action.payload;
    },
    setPickupRepositioning: (state, action) => {
      state.pickupRepositioning = action.payload;
      state.dropOffRepositioning = false;
    },
    setDropOffRepositioning: (state, action) => {
      state.dropOffRepositioning = action.payload;
      state.pickupRepositioning = false;
    },
    setOneLocationOnlyRepositioning: (state, action) => {
      state.oneLocationOnlyRepositioning = action.payload;
    },
    setMapCenter: (state, action) => {
      state.mapCenter = action.payload;
    },
    setIsSubscriptionUnpaid: (state, action) => {
      state.isSubscriptionUnpaid = action.payload;
    }
  }
});

export default uiSlice.reducer;
