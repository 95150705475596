import { createSlice } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import filter from 'lodash/filter';
import eq from 'lodash/eq';

const initialState = null;

export const savedManifestsSlice = createSlice({
  name: 'savedManifests',
  initialState,
  reducers: {
    setSavedManifests: (state, action) => action.payload,
    addSavedManifest: (state, action) =>
      sortBy([...state, action.payload], 'name'),
    updateSavedManifest: (state, action) =>
      map(state, savedManifest =>
        eq(savedManifest.id, action.payload.id) ? action.payload : savedManifest
      ),
    deleteSavedManifest: (state, action) =>
      filter(state, savedManifest => !eq(savedManifest.id, action.payload.id))
  }
});

export default savedManifestsSlice.reducer;
