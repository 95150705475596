/* eslint-disable no-console */

import useUserStore from './userStore';
import useOrganizationStore from './organizationStore';
import isNil from 'lodash/isNil';
import { genRandomHex } from '../helpers';
import { analyticsTrack, isAnalyticsEnabled } from '../../utils';

const useAnalytics = () => {
  const { getUser: user } = useUserStore();
  const { getOrganization: organization } = useOrganizationStore();
  const trackStartTime = new Date();

  const trackEvent = (message, data) => {
    if (!isAnalyticsEnabled) return;

    const defaultTrackingData = {
      guid: genRandomHex(12),
      environment: user.environment,
      is_connect: true,
      organization: organization.name,
      organization_id: organization.id,
      subscription_tier: organization.subscription_tier
    };

    let trackableData = defaultTrackingData;

    if (!isNil(data)) {
      trackableData = {
        ...trackableData,
        ...data
      };
    }

    analyticsTrack(message, trackableData);
  };

  return {
    trackEvent,
    trackStartTime
  };
};

export default useAnalytics;
