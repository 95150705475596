import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ children, size, containerClassName, white }) => {
  return (
    <div className={containerClassName} data-testid="spinner">
      <div className={`cx_standard-spinner-${size}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 70 71"
          fill="none"
        >
          <path
            d="M66.3156 37.194C66.3156 18.8835 51.4682 4.03613 33.1577 4.03613"
            stroke="url(#paint0_linear_20087_75390)"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_20087_75390"
              x1="35.8243"
              y1="4.03613"
              x2="54.9365"
              y2="11.9493"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={white ? '#FFFFFF' : '#CECBCF'} stopOpacity="0" />
              <stop
                offset="0.354167"
                stopColor={white ? '#FFFFFF' : '#CECBCF'}
                stopOpacity="0.04"
              />
              <stop offset="1" stopColor={white ? '#FFFFFF' : '#CECBCF'} />
            </linearGradient>
          </defs>
        </svg>
      </div>
      {children}
    </div>
  );
};

Spinner.defaultProps = {
  size: 200,
  containerClassName:
    'cx_l--position-relative cx_l--flex cx_l--flex-justify-center',
  white: false
};

Spinner.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number,
  containerClassName: PropTypes.string,
  white: PropTypes.bool
};

export default Spinner;
