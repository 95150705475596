import React from 'react';
import PropTypes from 'prop-types';
import useUIStore from '../hooks/uiStore';
import { eq, isNil } from 'lodash';

const Modal = ({
  id,
  modalCssClasses,
  overlayCssClasses,
  boxCssClasses,
  overlay = true,
  overlayClearsModal = true,
  children,
  onClose
}) => {
  const { getModal: modal, clearModal } = useUIStore();

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    clearModal();
  };

  return (
    !isNil(modal) &&
    eq(modal.id, id) && (
      <div className={`cx_modal ${modalCssClasses ? modalCssClasses : ''}`}>
        {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        {overlay ? (
          <div
            className={`cx_modal__overlay ${
              overlayCssClasses ? overlayCssClasses : ''
            }`}
            onClick={overlayClearsModal ? handleOnClose : null}
          />
        ) : null}
        <div className={`cx_modal__box ${boxCssClasses ? boxCssClasses : ''}`}>
          {children}
        </div>
      </div>
    )
  );
};

Modal.propTypes = {
  id: PropTypes.string,
  modalCssClasses: PropTypes.string,
  overlayCssClasses: PropTypes.string,
  boxCssClasses: PropTypes.string,
  overlayClearsModal: PropTypes.bool,
  overlay: PropTypes.bool,
  children: PropTypes.element,
  onClose: PropTypes.func
};

export default Modal;
