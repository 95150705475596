import { useState, useEffect } from 'react';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import eq from 'lodash/eq';
import map from 'lodash/map';
import each from 'lodash/each';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

const useGoogleMapDirections = ({
  mapInstance,
  mapsInstance,
  directions,
  componentRouteStyle
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [arePendingPromises, setArePendingPromises] = useState(false);
  const [directionsDisplays, setDirectionsDisplays] = useState([]);
  const [polylines, setPolylines] = useState([]);
  const [previousDirections, setPreviousDirections] = useState([]);
  const [pins, setPins] = useState([]);
  const [previousPins, setPreviousPins] = useState([]);
  const [routeStyle, setRouteStyle] = useState(componentRouteStyle);
  const [checkedVehiclesIds, setCheckedVehiclesIds] = useState([]);
  const [sendToDispatchChecked, setSendToDispatchChecked] = useState(true);
  const isVehiclesEmpty = directions.every(
    direction => !direction.vehicle_id && !direction.sent_to_dispatch
  );
  const checkedDirectionsWithVehiclesAndMp = directions.reduce(
    (prevDirections, direction) => {
      const directionWithSelectedVehicle =
        isVehiclesEmpty ||
        find(checkedVehiclesIds, id => eq(id, direction.vehicle_id)) ||
        (sendToDispatchChecked && direction.sent_to_dispatch) ||
        (direction.suppressPolylines && !direction.sent_to_dispatch);
      return directionWithSelectedVehicle
        ? [...prevDirections, direction]
        : prevDirections;
    },
    []
  );

  useEffect(() => {
    if (eq(routeStyle, 'straightLine')) {
      displayStraightLines();
    } else {
      displayDriverRoutes();
    }
  }, [
    mapInstance,
    mapsInstance,
    directions,
    checkedVehiclesIds,
    sendToDispatchChecked,
    routeStyle
  ]);

  useEffect(() => {
    if (isLoading || !arePendingPromises) return;

    displayDriverRoutes();
    setArePendingPromises(false);
  }, [isLoading]);

  const displayDriverRoutes = () => {
    if (isNil(mapInstance) || isNil(mapsInstance)) return;

    const newDirectionsDisplays = [];
    const newPins = [];
    const promises = [];

    clearRoutes();

    each(checkedDirectionsWithVehiclesAndMp, direction => {
      if (direction.suppressPolylines) {
        newPins.push(...direction.pins);
        return;
      }

      const previousDirectionsDisplay = find(directionsDisplays, {
        key: direction.key
      });

      if (
        !isNil(previousDirectionsDisplay) &&
        !hasDirectionChanged(direction)
      ) {
        previousDirectionsDisplay.setMap(mapInstance);
        newDirectionsDisplays.push(previousDirectionsDisplay);
        newPins.push(
          ...buildDriverRoutePins(direction, previousDirectionsDisplay)
        );
        return;
      }

      const waypoints = map(direction.pins, pin => ({
        location: `${pin.lat},${pin.lng}`
      }));

      promises.push(
        new Promise(resolve =>
          new mapsInstance.DirectionsService().route(
            {
              origin: waypoints[0].location,
              destination: waypoints[waypoints.length - 1].location,
              waypoints: waypoints.slice(1, -1),
              travelMode: 'DRIVING'
            },
            (response, status) => resolve({ response, status, direction })
          )
        )
      );
    });

    if (!isEmpty(promises)) {
      if (isLoading) return setArePendingPromises(true);

      setIsLoading(true);

      Promise.all(promises).then(responses => {
        each(responses, ({ response, status, direction }) => {
          if (eq(status, 'OK')) {
            const directionsDisplay = new mapsInstance.DirectionsRenderer();

            directionsDisplay.setMap(mapInstance);
            directionsDisplay.setOptions({
              key: direction.key,
              legs: response.routes[0].legs,
              polylineOptions: {
                strokeColor: direction.color,
                strokeWeight: 4
              },
              preserveViewport: true,
              suppressMarkers: true
            });
            directionsDisplay.setDirections(response);
            newDirectionsDisplays.push(directionsDisplay);
            newPins.push(...buildDriverRoutePins(direction, directionsDisplay));
          }
        });

        setInitialState({ newDirectionsDisplays, newPins });

        setIsLoading(false);
      });

      return;
    }

    setInitialState({ newDirectionsDisplays, newPins });
  };

  const displayStraightLines = () => {
    if (isNil(mapInstance) || isNil(mapsInstance)) return;

    const newPolylines = [];
    const newPins = [];

    clearRoutes();

    each(checkedDirectionsWithVehiclesAndMp, direction => {
      newPins.push(...direction.pins);

      if (direction.suppressPolylines) return;

      const previousPolyline = find(polylines, {
        key: direction.key
      });

      if (!isNil(previousPolyline) && !hasDirectionChanged(direction)) {
        previousPolyline.shadow?.setMap(mapInstance);
        previousPolyline.setMap(mapInstance);
        newPolylines.push(previousPolyline);
        return;
      }

      const { key, pins, color } = direction;
      const shadow = new mapsInstance.Polyline({
        path: map(pins, pin => new mapsInstance.LatLng(pin.lat, pin.lng)),
        strokeColor: '#000000',
        strokeWeight: 4,
        strokeOpacity: 0.2
      });
      const polyline = new mapsInstance.Polyline({
        key,
        shadow,
        path: map(pins, pin => new mapsInstance.LatLng(pin.lat, pin.lng)),
        strokeColor: color,
        strokeWeight: 3
      });

      shadow.setMap(mapInstance);
      polyline.setMap(mapInstance);
      newPolylines.push(polyline);
    });

    setInitialState({ newPolylines, newPins });
  };

  const setInitialState = ({
    newPolylines,
    newDirectionsDisplays,
    newPins
  }) => {
    setPolylines(newPolylines ?? []);
    setDirectionsDisplays(newDirectionsDisplays ?? []);
    setPreviousDirections(directions ?? []);
    setPreviousPins(pins ?? []);
    setPins(newPins ?? []);
  };

  const clearRoutes = () => {
    each(directionsDisplays, directionsDisplay =>
      directionsDisplay.setMap(null)
    );

    each(polylines, polyline => {
      polyline.shadow?.setMap(null);
      polyline.setMap(null);
    });
  };

  const hasDirectionChanged = direction => {
    const previousDirection = find(previousDirections, {
      key: direction.key
    });

    return !isEqual(
      map(direction.pins, pin => [pin.lat, pin.lng]),
      map(previousDirection?.pins, pin => [pin.lat, pin.lng])
    );
  };

  const buildDriverRoutePins = (direction, directionsDisplay) =>
    map(direction.pins, (pin, index) => {
      const legIndex = eq(index, 0) ? index : index - 1;
      const startOrEnd = eq(index, 0) ? 'start_location' : 'end_location';

      return {
        ...pin,
        lat: directionsDisplay.legs[legIndex][startOrEnd].lat(),
        lng: directionsDisplay.legs[legIndex][startOrEnd].lng()
      };
    });

  const handleRouteStyleChange = routeStyle => {
    setRouteStyle(routeStyle);
  };

  return {
    pins,
    previousPins,
    checkedVehiclesIds,
    setCheckedVehiclesIds,
    sendToDispatchChecked,
    setSendToDispatchChecked,
    handleRouteStyleChange
  };
};

export default useGoogleMapDirections;
