import { createSlice } from '@reduxjs/toolkit';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import eq from 'lodash/eq';

const initialState = null;

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action) => action.payload,
    addUser: (state, action) => {
      if (isNil(state)) return null;

      return {
        ...state,
        records: [action.payload, ...state.records]
      };
    },
    updateUser: (state, action) => {
      if (isNil(state)) return null;

      return {
        ...state,
        records: map(state.records, user =>
          eq(user.id, action.payload.id) ? action.payload : user
        )
      };
    }
  }
});

export default usersSlice.reducer;
