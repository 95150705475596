import { createSlice } from '@reduxjs/toolkit';
import map from 'lodash/map';
import eq from 'lodash/eq';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';

const initialState = null;

export const savedCustomersSlice = createSlice({
  name: 'savedCustomers',
  initialState,
  reducers: {
    setSavedCustomers: (state, action) => action.payload,
    addSavedCustomer: (state, action) => {
      if (isNil(state)) return null;

      return {
        ...state,
        records: [action.payload, ...state.records]
      };
    },
    updateSavedCustomer: (state, action) => {
      if (isNil(state)) return null;

      return {
        ...state,
        records: map(state.records, savedCustomer =>
          eq(savedCustomer.id, action.payload.id)
            ? action.payload
            : savedCustomer
        )
      };
    },
    removeSavedCustomer: (state, action) => {
      if (isNil(state)) return null;

      return {
        ...state,
        records: filter(state.records, i => !eq(i.id, action.payload.id))
      };
    }
  }
});

export default savedCustomersSlice.reducer;
