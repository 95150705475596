import { useDispatch, useSelector } from 'react-redux';
import { organizationSlice } from '../store/organization';
import eq from 'lodash/eq';
import isBefore from 'date-fns/isBefore';
import subMonths from 'date-fns/subMonths';
import { TIERS } from '../constants';

const useOrganizationStore = () => {
  const dispatch = useDispatch();

  const getOrganization = useSelector(state => state.organization);

  const getOrganizationId = useSelector(state => {
    if (!state.organization) return null;
    return state.organization.id;
  });

  const setOrganization = ({
    id,
    name,
    address,
    vehicle_capacity_units,
    vehicle_capacity_unit,
    unit_description,
    trial_ends_in,
    vehicle_limit,
    can_send_to_dispatch,
    default_pickup_address,
    trial_expired,
    trial_end_date,
    service_end_date,
    pay_now_url,
    working_hours_start_at,
    working_hours_end_at,
    time_zone,
    duration_at_pickup_in_minutes,
    duration_at_drop_off_in_minutes,
    default_vehicle_address,
    subscription_id,
    subscription_tier,
    subscription_paid_for,
    subscription_contract_url,
    subscription_billing_cycle,
    subscription_vehicle_payment_pending,
    trial_agreed,
    plan_it_status,
    max_deliveries_per_route,
    billing_email,
    bypass_billing_enabled,
    notification_settings
  }) => {
    dispatch(
      organizationSlice.actions.setOrganization({
        id,
        name,
        address,
        vehicle_capacity_units,
        vehicle_capacity_unit,
        unit_description,
        trial_ends_in,
        vehicle_limit,
        can_send_to_dispatch,
        default_pickup_address,
        trial_expired,
        trial_end_date,
        service_end_date,
        pay_now_url,
        working_hours_start_at,
        working_hours_end_at,
        time_zone,
        duration_at_pickup_in_minutes,
        duration_at_drop_off_in_minutes,
        default_vehicle_address,
        subscription_id,
        subscription_tier,
        subscription_paid_for,
        subscription_contract_url,
        subscription_billing_cycle,
        subscription_vehicle_payment_pending,
        trial_agreed,
        plan_it_status,
        max_deliveries_per_route,
        billing_email,
        bypass_billing_enabled,
        notification_settings
      })
    );
  };

  const updateOrganization = organization => {
    dispatch(organizationSlice.actions.updateOrganization(organization));
  };

  const showHistoricalData = date => {
    const minDate = subMonths(new Date().setHours(0, 0, 0, 0), 13);
    return (
      !eq(getOrganization?.subscription_tier, TIERS.STARTER) ||
      !isBefore(date, minDate)
    );
  };

  const isTrialTier = () => eq(getOrganization?.subscription_tier, TIERS.TRIAL);

  const isStarterTier = () =>
    eq(getOrganization?.subscription_tier, TIERS.STARTER);

  const isProfessionalTier = () =>
    eq(getOrganization?.subscription_tier, TIERS.PROFESSIONAL);

  return {
    getOrganization,
    setOrganization,
    updateOrganization,
    getOrganizationId,
    showHistoricalData,
    isTrialTier,
    isStarterTier,
    isProfessionalTier
  };
};

export default useOrganizationStore;
