import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty, isString, eq } from 'lodash';

const ImageUpload = ({ id, name, value, onChange, disabled }) => {
  const imgPreviewContainer = useRef();
  const imgPreview = useRef();
  const imgUpload = useRef();
  const imgInput = useRef();

  const validRefs = () => {
    return (
      !isNil(imgPreviewContainer.current) &&
      !isNil(imgPreview.current) &&
      !isNil(imgUpload.current) &&
      !isNil(imgInput.current)
    );
  };

  useEffect(() => {
    if (isString(value) && !isEmpty(value)) return setImageFromUrl(value);

    if (!validImage(value)) return;

    const reader = new FileReader();
    reader.onload = e => {
      setImageFromUrl(e.target.result);
    };

    reader.readAsDataURL(value);
  }, [value]);

  const validImage = file => {
    const fileType = file?.type;
    return eq(fileType, 'image/png') || eq(fileType, 'image/jpeg');
  };

  const setImageFromUrl = url => {
    if (!validRefs()) return;

    imgPreview.current.setAttribute('src', url);
    imgPreviewContainer.current.classList.remove('hidden');
    imgUpload.current.classList.add('hidden');
  };

  const handleDragOver = evt => evt.preventDefault();

  const handleDrop = evt => {
    if (!validRefs()) return;

    imgInput.current.files = evt.dataTransfer.files;
    handleChange();
    evt.preventDefault();
  };

  const handleChange = () => {
    if (!validRefs()) return;

    const input = imgInput.current;
    if (isEmpty(input.files) || !validImage(input.files[0])) {
      return handleRemove();
    }

    onChange(input.files[0]);
  };

  const handleRemove = evt => {
    if (!validRefs()) return;

    if (evt) evt.preventDefault();

    imgInput.current.value = '';
    imgPreview.current.setAttribute(
      'src',
      'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
    );
    imgPreviewContainer.current.classList.add('hidden');
    imgUpload.current.classList.remove('hidden');

    onChange(null);
  };

  return (
    <>
      <label htmlFor={name} onDragOver={handleDragOver} onDrop={handleDrop}>
        <div className="cx_field--image-upload" ref={imgUpload} />
        <div
          className="cx_field--image-preview-container hidden"
          ref={imgPreviewContainer}
        >
          <button className="cx_field--image-remove" onClick={handleRemove} />
          <img
            className="img-responsive cx_field--image-preview"
            alt="Uploaded File"
            src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            ref={imgPreview}
          />
        </div>
      </label>
      <div className="hidden">
        <input
          id={id}
          name={name}
          onChange={handleChange}
          accept="image/png,image/jpeg"
          type="file"
          ref={imgInput}
          disabled={disabled}
        />
      </div>
    </>
  );
};

ImageUpload.defaultProps = {
  value: null
};

ImageUpload.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]), // URL or File
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default ImageUpload;
